const getters = {
    getAll (state) {
      return state.all
    },
    getPayload (state) {
      return state.payload
    },
    getItemById: (state) => (id) => {
      return state.all.find(item => item.id === id)
    },
    getItemByProperty: (state) => (property, value) => {
      return state.all.find(item => item[property] === value)
    },
    getItemByName: (state) => (name) => {
      return state.all.find(item => item.name === name)
    }
  }
  
  const actions = {
  }
  
  const mutations = {
    init (state, set, Model) {
      state.all = []
      for (let item of set) {
        state.all.push(new Model(item))
      }
    },
    setCurrent (state, item) {
      state.current = item
    },
    unsetCurrent (state) {
      state.current = null
    },
    create (state, item, Model) {
      state.all.push(new Model(item))
    },
    update (state, item, Model) {
  
    },
    updatePropertyOfItemByName (state, payload) {
      let newState = []
      for (let thisItem of state.all) {
        if (thisItem.name === payload.itemName) {
          thisItem[payload.property] = payload.value
          newState.push(thisItem)
        } else {
          newState.push(thisItem)
        }
      }
      state.all = newState
    },
    delete (state, item) {
      state.all = state.all.filter(function (thisItem) {
        return thisItem._id !== item._id
      })
    },
    setProperty (state, payload) {
      state[payload.property] = payload.value
    },
  setLibrary (state, libraryData) {
    // Stores data from the library into the state base array
    // The library is a bunch of settings and presets shared accross projects
    state.library = libraryData
  },
  setTemplate (state, templateData) {
    // Stores data from the template into the state template array
    // A template is a bunch of settings shared accross scenes within a project
    state.template = templateData
  },
  setLibraryTemplatePayload (state, sceneData) {
    // Compile data from a payload in the scenedata, template and library. This method
    // is used with singular data, data which is used once in the scene. For example
    // player, environment, postprocessing and background
    // A payload is a bunch of settings which can be applied to the scene
    if (sceneData && state.template !== undefined) {
      const theTemplate = state.template.find(item => item.name === sceneData.template)
      if (theTemplate !== undefined) {
        if (state.library !== undefined) {
          const lib = state.library.find(item => item.name === theTemplate.library)
          if (lib !== undefined) {
            state.payload = {...lib.payload, ...theTemplate.payload, ...sceneData.payload}
          } else {
            state.payload = {...theTemplate.payload, ...sceneData.payload}
          }
        } else {
          state.payload = {...theTemplate.payload, ...sceneData.payload}
        }
      } else {
        state.payload = sceneData.payload
      }
    }
  } 
}
  
export default {
  getters,
  actions,
  mutations
}
  