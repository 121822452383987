<template>
  <configurator/>
</template>

<script>
// @ is an alias to /src
import configurator from 'Components/configurator'

export default {
  name: 'home',
  components: {
    configurator
  }
}
</script>
