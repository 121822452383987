<template>
<v-theme-provider>
  <v-app>
    <!-- <v-content> -->
      <router-view></router-view>
    <!-- </v-content> -->
  </v-app>
</v-theme-provider>
</template>

<script>
// A container for some global methods
window.configuratorapi = {}

export default {
  name: 'App',
  data () {
    return {
      
    }
  },
  mounted () {
    this.$vuetify.theme.dark = true
  },

}
</script>