<script>
import menuSubGroup from './menusubgroup'
import menuOptions from './menu-options'
import store from '@/store'

export default {
  name: 'menuGroup',
  components: {
    menuSubGroup,
    menuOptions
  },
  props: {
    menu: Object
  },
  data () {
    return {
      active: false
    }
  },
  computed: {
    icon () {
      return this.menu.icon
    },
    sceneLoaded () {
      return store.state.scenes.sceneLoaded
    },
  },
  mounted () {
      this.active = this.menu.initialOpen
  },

  methods: {
  }  
}
</script>

<template>
  <v-list-group 
    v-if="sceneLoaded"
    color="primary"
    v-model="active"
  >
    <v-icon 
      slot="prependIcon"
    >
      {{menu.icon}}
    </v-icon>
    <template 
      v-slot:activator
    >
      <v-list-item-title 
        v-text="menu.title"
        class="title"
      ></v-list-item-title>
    </template>

    <div 
      v-if="menu.submenu"
    >
      <div 
        v-for="subitem in menu.submenu" 
        :key="subitem.name"
      >
        <menuSubGroup 
          :menu="subitem"
        />
      </div>
    </div>

    <menuOptions 
      :menu="menu"
    />
  </v-list-group>
</template>