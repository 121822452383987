import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: colors.pink.lighten1, // #E53935
        secondary: '#81D4FA', //colors.lightblue.lighten3
        accent: '#01579B', //colors.lightblue.darken4
        card: colors.grey.lighten4, // #3F51B5
        cardaccent: colors.grey.lighten2,
        textcontrast: colors.grey.darken4

      },
      dark: {
        primary: colors.pink.darken2, // #E53935
        secondary: colors.green.darken3, //colors.lightblue.lighten3
        accent: '#01579B', //colors.lightblue.darken4
        card: '#313131',
        cardaccent: colors.grey.darken2,
        textcontrast: colors.grey.lighten4
      },
    },
  },  
});
