import configure from './configure'
import store from '@/store'

const initVisibility = async function () {
  const visibilitysingle = store.getters['visibilitysingle/getAll']
  if (visibilitysingle) {
    for (let opt of visibilitysingle) {
      await configure.SetVisibilitySingle(opt.visible, opt.objects, opt.matchexact)
    }
  }
  const visibilityoptionsgroup = store.getters['visibilitygroup/getAll']
  if (visibilityoptionsgroup !== undefined) {
    for (let group of visibilityoptionsgroup) {
      let option = group.options.find(item => item.name === group.visible)
      if (option === null) {
        option = group.options[0]
      }
      await configure.SetVisibilityGroup(group, option, option.matchNameExactly)
    }
  }
  // const state = store.getters['liquid/getState']
  // if (state.liquid !== undefined) {
  //   for (let liquid of state.liquid) {
  //     if (liquid.visibility !== undefined) {
  //       configure.SetVisibilitySingle(liquid.visibility.visible, liquid.visibility.objects, false)
  //     }
  //   }
  // }
}

const initViews = function (startView) {
  if (startView) {
    const myView = store.getters['views/getItemByName'](startView)
    if (myView) configure.setView(myView.payload)
  } else {
    const views = store.getters['views/getAll']
    if (views.length > 0) configure.setView(views[0].payload)
  }
}

const initMaterials = async function () {
  const materials = store.getters['material/getAll']
  if (materials !== undefined) {
    for (let group of materials) {
      let option = group.options[0]
      if (group.visible !== undefined) {
        option = group.options.find(item => item.name === group.visible)
        if (option === null) {
          option = group.options[0]
        }
      }
      if (group.options.length > 0) {
        await configure.SetMaterialOption(group, option)
      }
      store.commit('material/updateSelectedMaterial', {material: group, option: option})
    }
  }

  const swappees = store.getters['materialswappable/getAllSwappee']
  const swappableMaterials = store.getters['materialswappable/getAllSwappable']
  if (swappableMaterials !== undefined) {
    for (let swappable of swappableMaterials) {
      const swappee = swappees.find(swappee => swappee.name === swappable.visible)
      const mltOption = swappee.options.find(op => op.name === swappee.visible)

      //add additional payload
      let payload = mltOption.payload
      if (swappable.payload) {
        payload = {...payload, ...swappable.payload}
      }

      await configure.SetMaterialOption(swappable, {payload: payload})
    }
  }
  console.log('MATERIALS READY')

  // const state = store.getters['liquid/getState']
  // if (state.liquid !== undefined) {
  //   for (let liquid of state.liquid) {
  //     if (liquid.material !== undefined) {
  //       configure.SetMaterialOption(liquid.material, liquid.material, liquid.liquidsets)
  //     }
  //   }
  // }
}

const initEnvironment = function () {
  const environment = store.getters['environment/getAll']
  if (environment[0] !== undefined) {
    let option = environment[0].options[0]
      if (environment[0].visible !== undefined) {
        option = environment[0].options.find(item => item.name === environment[0].visible)
        if (option === null) {
          option = environment[0].options[0]
        }
      }
      if (environment[0].options.length > 0) {
        configure.SetEnvironment(option.payload)
      }
      store.commit('environment/updateSelectedEnvironment', {environment: environment, option: option})
  }
}

export default {
    initVisibility,
    initViews,
    initMaterials,
    initEnvironment
}
