import _base from './_base-module'
import templateData from 'ProjectData/template'

const state = {
  sink: {},
  keys: [],
  options: [],
  baseurl: '',
  chain: '',
  headers: '',
}

const getters = {
  ..._base.getters,
  getState (state) {
    return state
  }
}

const actions = {
  fetchData ({ commit }) {
    templateData.liquifire && commit('setState', templateData.liquifire)
  }
}

const mutations = {
  setState(state, data) {
    state.sink = data.sink
    state.keys = data.keys
    state.options = data.options
    state.baseurl = data.baseurl
    state.chain = data.chain
    state.headers = data.headers
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
