<script>
import store from '../store'
import configuratordrawer from './configurator-drawer'

export default {
    name: 'bottomnavigation',
    components: {
        configuratordrawer
    },
    props: {
    },
    data: () => ({
    }),
    computed: {
        isExpanded () {
            return store.getters['ui/getItemByName']('bottomnav')
        },
        scroll () {
            if (this.isExpanded) {
                return `overflow: auto;`
            } else {
                return `overflow: hidden;`
            }
        },
        toggletext () {
            if (this.isExpanded) {
                return `Hide menu`
            } else {
                return `Show menu`
            }
        }
    },
    methods: {
        toggleExpanded () {
            store.commit('ui/updateItemByName', {key: 'bottomnav', value: !this.isExpanded})
        }
    }   
}
</script>

<template>
    <v-container
        class="pa-0"
        :style="scroll"
    >
        <v-toolbar
            class="elevation-0 pl-0"
            color="card"
        >
            <span
                @click="toggleExpanded()"
                class="ml-n1"
            >
                <v-icon
                    large
                >
                    {{ $store.getters['ui/getItemByName']('bottomnav') ? 'mdi-chevron-down' : 'mdi-chevron-up' }}
                </v-icon>
                {{toggletext}}
            </span>
        </v-toolbar>
        <configuratordrawer/>
    </v-container>
</template>

<style lang="scss" scoped>
</style>