import _base from './_base-module'
import templateData from 'ProjectData/template'
import libraryData from 'ProjectData/library'

const state = {
  library: [],
  template: [],
  payload: {},
  all: []
}

const getters = {
  ..._base.getters
}


const actions = {
  setCurrentEnvironment ({ commit }, {environment, option}) {
    commit('updateSelectedEnvironment', {environment: environment, option: option})
  },
  setCurrentOptionPayloadProperty ({commit}, {environment, option, property, value}) {
    commit('setCurrentOptionPayloadProperty', {environment: environment, option: option, property: property, value: value})
  },
  fetchData ({ commit }, {scene}) {
    commit('setLibrary', libraryData.environment)
    commit('setTemplate', templateData.environment)
    commit('setLibraryTemplatePayload', scene.environment)
  }
}

const mutations = {
  ..._base.mutations,
  clearAll (state) {
    state.all = []
  },
  addEnvironment (state, environment) {
    state.all.push(environment)
  },
  setCurrentOptionPayloadProperty (state, payload) {
    state.all = state.all.map(item => {
      if (item.name === payload.environment.name) {
        console.log('item.name', item.name)
        item.options = item.options.map(option => {
          if (option.name === payload.option.name) {
            console.log('option.name', option.name)
            option.payload[payload.property] = payload.value
          }
          return option
        })
      } return item
    })
  },
  updateSelectedEnvironment (state, payload) {
    console.log('payload.environment', payload.environment)
    state.all = state.all.map(item => {
      if (item.name === payload.environment.name) {
        item.currentTitle = payload.option.title
        item.swatch = payload.option.swatch
        item.icon = payload.option.icon
        item.visible = payload.option.name
      } return item
    })
  }, 
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
