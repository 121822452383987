<script>
import configure from '@/tools/configurator/configure'
import materialTools from '@/tools/babylon/materials'

import liquidTools from '@/tools/babylon/liquid'
import store from '@/store'

export default {
  name: 'liquidkeygroup',
  components: {
  },
  props: {
    optionname: String,
    keygroup: Object
  },
  computed: {
    liquid () {
      return store.getters['liquid/getItemByName'](this.optionname)
    },
    material () {
      return store.getters['material/getItemByName'](this.liquid.materialname)
    },
    materialoption () {
      return this.material.options.find(option => option.name === this.liquid.materialoption)
    },
    scenematerial () {
      return materialTools.getMtl(this.materialoption.id)
    },
    locationList () {
      // const activeKeygroups = store.getters['keygroups/getAllActive']
      // let availableLocations = this.liquid.options.location.value.map(location => {
      //   let takenLocation = activeKeygroups.find(kg => kg.location === location.value)
      //   if (!takenLocation || this.keygroupdata.location === location.value) return location
      // })
      // return availableLocations.filter(item => item != undefined)
      return this.liquid.options.location.value
    }
    // productid () {
    //   return this.liquid.prodID
    // }
  },
  data: function () {
    return {
      keygroupdata: {
        location: '',
        type: '',
        imageurl1: '',
        embroideryurl: '',
        embroiderycolors: [],
        text1: '',
        text2: '',
        truetypefont: '',
        wilcomfont: '',
        fontcolor: '',
        imprinttype: '',
        //nike specific
        imageurl2: '',
        imageurl3: ''
      }
    }
  },
  mounted () {
    //set the default values
    Object.entries(this.liquid.options).forEach(([key,value]) => {
        this.keygroupdata[key] = value.default
    })
  },
  methods: {
    changeOverflow (myoptionname) {
      console.log('myoptionname', myoptionname)
      let myliquid = store.getters['liquid/getItemByName'](myoptionname)
      let mymaterial = store.getters['material/getItemByName'](myliquid.materialname)
      let mymaterialoption = mymaterial.options.find(option => option.name === myliquid.materialoption)
      let myscenematerial = materialTools.getMtl(mymaterialoption.id)

      let keygroupliquidpayload = liquidTools.createKeygroupLiquidPayload(myliquid, this.keygroup, this.keygroupdata)
      if (this.keygroup) {
        store.dispatch('keygroups/updateLiquid', {basenumber: this.keygroup.basenumber, liquid: keygroupliquidpayload})
        store.dispatch('keygroups/setLocation', {basenumber: this.keygroup.basenumber, location: this.keygroupdata.location})
      }
      let fullLiquidPayload = liquidTools.updateMaterialFromKeygroups()
      liquidTools.injectProductId(myoptionname, fullLiquidPayload)
      store.commit('material/updateMaterialoptionLiquid', {materialname: mymaterial.name, optionname: mymaterialoption.name, channel: myliquid.channel, liquid: fullLiquidPayload})
      
      materialTools.applyMaterialSettings(myscenematerial, mymaterialoption.payload)
    },
    changeText (event) {
      //only react if the user presses enter or tab
      if (event.keyCode === 13 || event.keyCode === 9) {
        this.changeOverflow(this.optionname)
      }
    },
    changeType (value) {
      this.changeOverflow(this.optionname)
    },
    showControl (controlid) {
      return liquidTools.isOptionInRelation( this.liquid, this.keygroupdata.type, controlid)
    },
    deactivateKeygroup () {
      this.keygroupdata.type = 'BASIC'
      this.changeOverflow(this.optionname)
      store.dispatch('keygroups/deactivate', {basenumber: this.keygroup.basenumber})

    }
  }
}
</script>

<template>
<v-card color="card" tile flat>
  <v-btn 
    raised
    class="mb-2"
    @click="deactivateKeygroup()"
  >
    Remove Personalization {{keygroup.sequential}}
    <v-icon right dark>mdi-delete</v-icon>
  </v-btn>        



  <v-select
    v-if="showControl('location')"
    v-model="keygroupdata.location"
    class="mx-0 mb-2"
    :items="locationList"
    item-text="text"
    item-value="value"
    label="Location"
    persistent-hint
    filled
    @change="changeOverflow(optionname)"
  ></v-select>

    <v-select
    v-model="keygroupdata.type"
    class="mx-0 mb-2"
    :items="liquid.options.type.value"
    item-text="text"
    item-value="value"
    label="Type"
    persistent-hint
    filled
    @change="changeType($event)"
  ></v-select>

  <v-text-field
    v-if="showControl('imageurl1')"
    v-model="keygroupdata.imageurl1"
    class="ml-3 mr-0 mb-2"
    label="Paste Image URL and press enter"
    @keydown="changeText($event)"
  ></v-text-field>

  <v-text-field
    v-if="showControl('imageurl2')"
    v-model="keygroupdata.imageurl2"
    class="ml-3 mr-0 mb-2"
    label="Paste Image URL and press enter"
    @keydown="changeText($event)"
  ></v-text-field>

  <v-text-field
    v-if="showControl('imageurl3')"
    v-model="keygroupdata.imageurl3"
    class="ml-3 mr-0 mb-2"
    label="Paste Image URL and press enter"
    @keydown="changeText($event)"
  ></v-text-field>

  <v-text-field
    v-if="showControl('embroideryurl')"
    v-model="keygroupdata.embroideryurl"
    class="ml-3 mr-0 mb-2"
    label="Paste Artworks Embroidery ID and press enter"
    @keydown="changeText($event)"
  ></v-text-field>

  <v-select
    v-if="showControl('embroiderycolors')"
    v-model="keygroupdata.embroiderycolors"
    class="mx-0 mb-2"
    :items="liquid.options.embroiderycolors.value"
    label="Select Embroidery colors"
    persistent-hint
    filled
    multiple
    chips
    @change="changeOverflow(optionname)"
  ></v-select>

  <v-text-field
    v-if="showControl('text1')"
    v-model="keygroupdata.text1"
    class="ml-3 mr-0 mb-2"
    label="Type text 1 and press enter"
    @keydown="changeText($event)"
  ></v-text-field>

  <v-text-field
    v-if="showControl('text2')"
    v-model="keygroupdata.text2"
    class="ml-3 mr-0 mb-2"
    label="Type text 2 and press enter"
    @keydown="changeText($event)"
  ></v-text-field>

  <v-text-field
    v-if="showControl('text3')"
    v-model="keygroupdata.text3"
    class="ml-3 mr-0 mb-2"
    label="Type text 3 and press enter"
    @keydown="changeText($event)"
  ></v-text-field>

  <v-text-field
    v-if="showControl('text4')"
    v-model="keygroupdata.text4"
    class="ml-3 mr-0 mb-2"
    label="Type text 4 and press enter"
    @keydown="changeText($event)"
  ></v-text-field>

  <v-select
  v-if="showControl('truetypefont')"
    v-model="keygroupdata.truetypefont"
    class="mx-0 mb-2"
    :items="liquid.options.truetypefont.value"
    label="Select font"
    persistent-hint
    filled
    @change="changeOverflow(optionname)"
  ></v-select>

  <v-select
  v-if="showControl('wilcomfont')"
    v-model="keygroupdata.wilcomfont"
    class="mx-0 mb-2"
    :items="liquid.options.wilcomfont.value"
    label="Select font"
    persistent-hint
    filled
    @change="changeOverflow(optionname)"
  ></v-select>


  <v-select
  v-if="showControl('fontcolor')"
    v-model="keygroupdata.fontcolor"
    class="mx-0 mb-2"
    :items="liquid.options.fontcolor.value"
    item-text="text"
    item-value="value"
    label="Select font color"
    persistent-hint
    filled
    @change="changeOverflow(optionname)"
  ></v-select>

  <v-select
  v-if="showControl('imprinttype')"
    v-model="keygroupdata.imprinttype"
    class="mx-0 mb-2"
    :items="liquid.options.imprinttype.value"
    label="Imprint type"
    hint="Pick an imprint type"
    persistent-hint
    filled
    @change="changeOverflow(optionname)"
  ></v-select>

</v-card>

</template>

<style lang="scss" scoped>
</style>