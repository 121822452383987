import _base from './_base-module'
import templateData from 'ProjectData/template'

const getOption = function (option) {
  if (option.visibilitygroup) {
    return templateData.visibilitygroup.find(item => item.name === option.visibilitygroup)
  }
  return null
}

const state = {
  all: []
}

const getters = {
  ..._base.getters
}

const actions = {
  updateSelectedGroup ({ commit }, {group, option}) {
    commit('updateSelectedGroup', {group: group, option: option})
  },    
  fetchData ({ commit }, {menu}) {
    commit('clear')
    let options = []
    for (let menuitem of menu) {
      if (menuitem.options) {
        for (let option of menuitem.options) {
          options.push(option)
        }
      }
      if (menuitem.submenu) {
        for (let submenuitem of menuitem.submenu)
        {
          if (submenuitem.options) {
            for (let option of submenuitem.options) {
              options.push(option)
            }
          }
        }
      }
    }
    for (let option of options) {
      const theOption = getOption(option)
      if (theOption !== null) {
        if (theOption.visible === undefined) {
          theOption.visible = theOption.options[0].name
        }
        let currentoption = theOption.options.find(item => item.name === theOption.visible)
        commit('addOption', theOption)
        commit('updateSelectedGroup', {group: theOption, option: currentoption})
      }
    }
  }
}

const mutations = {
  ..._base.mutations,
  addOption (state, theOption) {
    if (theOption !== null) {
      state.all.push(theOption)
    }
  },
  clear (state) {
    state.all = []
  },
  updateSelectedGroup (state, payload) {
    state.all = state.all.map(item => {
      if (item.name === payload.group.name) {
        item.currentTitle = payload.option.title
        item.swatch = payload.option.swatch
        item.icon = payload.option.icon
        item.visible = payload.option.name
      } return item
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
