<script>
import store from 'Store'

export default {
  name: 'textureload',
  components: {

  },
  data: () => ({

  }),
  computed: {
    showprogress () {
      // return true
      return store.state.ui.textureloading
    },

  },

  methods: {
  },
  mounted () {
  }
}
</script>

<template>
<v-sheet v-if="showprogress" class="loader" transition="fade-transition">

  <v-row
    class="fill-height"
    align-content="end"
    justify="center"
  >
    <v-col
      class="text-h5  text-center text--secondary"
      cols="12"
    >
      Updating texture
    </v-col>
  </v-row>
  <v-progress-linear
    color="primary"
    class="bar"
    indeterminate
    height="6"
  ></v-progress-linear>

</v-sheet>
</template>

<style lang="scss" scoped>
.loader {
  overflow: hidden;
  position: absolute;
  z-index:3;
  height: 100%;
  width: 100%;
  pointer-events: none;
  background-color: #ffffff00
}
.bar {
  position: absolute;
  bottom: 0px;
  left: 0px;
}
</style>
