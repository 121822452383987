class MenuItem {
  constructor(menuitem) {
    this.name = menuitem.name
    this.title = menuitem.title
    this.icon = menuitem.icon
    this.options = menuitem.options ? menuitem.options : null
    if (menuitem.initialOpen == null) {
      this.initialOpen = false
    }else{
      this.initialOpen = menuitem.initialOpen
    }
    if (menuitem.submenu) {
      this.submenu = []
      for (let sub of menuitem.submenu) {
        this.submenu.push(new MenuItem(sub))
      }
    } else {
      this.submenu = null  
    }
  }
}

export default MenuItem