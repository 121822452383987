import Vue from 'vue'
import Vuex from 'vuex'

import scenes from './modules/scenes'
import menu from './modules/menu'
import material from './modules/material'
import materialswappable from './modules/materialswappable'
import player from './modules/player'
import background from './modules/background'
import lighting from './modules/lighting'
import environment from './modules/environment'
import postprocessing from './modules/postprocessing'
import presets from './modules/presets'
import views from './modules/views'
import visibilitysingle from './modules/visibilitysingle'
import visibilitygroup from './modules/visibilitygroup'
import ui from './modules/ui'
import liquifire from './modules/liquifire'
import labels from './modules/labels'
import transform from './modules/transform'
import text from './modules/text'
import liquid from './modules/liquid'
import keygroups from './modules/keygroups'

Vue.use(Vuex)

const getters = {}
const state = {}
const actions = {}
const mutations = {}

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    background,
    lighting,
    environment,
    labels,
    material,
    materialswappable,
    menu,
    player,
    postprocessing,
    presets,
    scenes,
    ui,
    liquifire,
    views,
    visibilitygroup,
    visibilitysingle,
    transform,
    text,
    liquid,
    keygroups
  },
  strict: true
})
