class LiquiFire {
  
  constructor(configuration) {
    this.options = {}
    configuration.options.forEach((option) => { this.options[option.name] = option.value })
  
    this.keys = {}
    configuration.keys.forEach((key) => { this.keys[key.name] = key.value })
  
    this.baseUrl = configuration.baseurl
    this.call = `call=url[${configuration.chain}]`
    this.headers = configuration.headers
  
    this.sink = {...configuration.sink}
  }
  
  setSink(key, value) { this.sink[key] = value }
  
  setKey(key, value) { this.keys[key] = value }
  
  setOption(key, value) { this.options[key] = value }
  
  get textureUrl() {
    const sinkParts = Object.entries(this.sink).map(([key, value]) => `${key}[${value}]`)
    const sink = `sink=${sinkParts.join(',')}`
  
    const queryParts = [
      ...Object.entries(this.keys).map(([key, value]) => `set=key[${key}],value[${value}]`),
      ...Object.entries(this.options).map(([key, value]) => `set=key[${key}],value[${value}]`),
      this.call,
      this.headers,
      sink
    ]
  
    return `${this.baseUrl}?${queryParts.join('&')}`
  }
}

export default LiquiFire;