const project = {
  ui: {
    infopanel: false,
    bottomnav: true,
    inspector: false,
    headless: false,
    projecttitle: '',
    headerlogo: 'footjoypoc/assets/AC-FJ.png',
    background: {
      image: './img/Med_vt_rv.png',
      color: '#5F2E2E',
      type: 'babylon'
    }
  },
  scenes: [
    {
      engine: 'babylon',
      scenetitle: 'US-F20-05 Polo',
      route: 'US-F20-05',
      scenefile: 'footjoypoc/assets/geometry/US-F20-05.babylon',
      view: 'start',
      header: 'Footjoy POC',
      subheader: 'Configurator by Klaas Nienhuis',
      banner: 'footjoypoc/assets/US-F20-05.png',
      menu: [
        {
          name: 'liquid',
          title: 'Personalize',
          icon: 'mdi-palette',
          options: [
            {material: 'shirt_US_F20_05'},
            {liquid: 'shirt_US_F20_05'}
          ]
        },
      ],
      player: {template: 'avoidunder', payload: {}},
      background: {template: 'white', payload: {}},
      environment: {template: 'softvignette', payload: {}},
      postprocessing: {template: 'ssao', payload: {}},
      lighting: {template: 'footjoy_2point', payload: {}},
      annotations: [
      ]
    },
    {
      engine: 'babylon',
      scenetitle: 'US-F20-08 Polo',
      route: 'US-F20-08',
      scenefile: 'footjoypoc/assets/geometry/US-F20-08.babylon',
      view: 'start',
      header: 'Footjoy POC',
      subheader: 'Configurator by Klaas Nienhuis',
      banner: 'footjoypoc/assets/US-F20-08.png',
      menu: [
        {
          name: 'liquid',
          title: 'Personalize',
          icon: 'mdi-palette',
          options: [
            {material: 'shirt_US_F20_08'},
            {liquid: 'shirt_US_F20_08'}
          ]
        },
      ],
      player: {template: 'avoidunder', payload: {}},
      background: {template: 'white', payload: {}},
      environment: {template: 'softvignette', payload: {}},
      postprocessing: {template: 'ssao', payload: {}},
      lighting: {template: 'footjoy_2point', payload: {}},
      annotations: [
      ]
    },
    {
      engine: 'babylon',
      scenetitle: 'US-F20-09 Polo',
      route: 'US-F20-09',
      scenefile: 'footjoypoc/assets/geometry/US-F20-05.babylon',
      view: 'start',
      header: 'Footjoy POC',
      subheader: 'Configurator by Klaas Nienhuis',
      banner: 'footjoypoc/assets/US-F20-09.png',
      menu: [
        {
          name: 'liquid',
          title: 'Personalize',
          icon: 'mdi-palette',
          options: [
            {material: 'shirt_US_F20_09'},
            {liquid: 'shirt_US_F20_09'}
          ]
        },
      ],
      player: {template: 'avoidunder', payload: {}},
      background: {template: 'white', payload: {}},
      environment: {template: 'softvignette', payload: {}},
      postprocessing: {template: 'ssao', payload: {}},
      lighting: {template: 'footjoy_2point', payload: {}},
      annotations: [
      ]
    },
    {
      engine: 'babylon',
      scenetitle: 'US-F20-10 Polo',
      route: 'US-F20-10',
      scenefile: 'footjoypoc/assets/geometry/US-F20-10.babylon',
      view: 'start',
      header: 'Footjoy POC',
      subheader: 'Configurator by Klaas Nienhuis',
      banner: 'footjoypoc/assets/US-F20-10.png',
      menu: [
        {
          name: 'liquid',
          title: 'Personalize',
          icon: 'mdi-palette',
          options: [
            {material: 'shirt_US_F20_10'},
            {liquid: 'shirt_US_F20_10'}
          ]
        },
      ],
      player: {template: 'avoidunder', payload: {}},
      background: {template: 'white', payload: {}},
      environment: {template: 'softvignette', payload: {}},
      postprocessing: {template: 'ssao', payload: {}},
      lighting: {template: 'footjoy_2point', payload: {}},
      annotations: [
      ]
    },
    {
      engine: 'babylon',
      scenetitle: 'US-F20-13 Polo',
      route: 'US-F20-13',
      scenefile: 'footjoypoc/assets/geometry/US-F20-13.babylon',
      view: 'start',
      header: 'Footjoy POC',
      subheader: 'Configurator by Klaas Nienhuis',
      banner: 'footjoypoc/assets/US-F20-13.png',
      menu: [
        {
          name: 'liquid',
          title: 'Personalize',
          icon: 'mdi-palette',
          options: [
            {material: 'shirt_US_F20_13'},
            {liquid: 'shirt_US_F20_13'}
          ]
        },
      ],
      player: {template: 'avoidunder', payload: {}},
      background: {template: 'white', payload: {}},
      environment: {template: 'softvignette', payload: {}},
      postprocessing: {template: 'ssao', payload: {}},
      lighting: {template: 'footjoy_2point', payload: {}},
      annotations: [
      ]
    },
    {
      engine: 'babylon',
      scenetitle: 'US-OW-156 Jacket',
      route: 'US-OW-156',
      scenefile: 'footjoypoc/assets/geometry/US-OW-156.babylon',
      view: 'start',
      header: 'Footjoy POC',
      subheader: 'Configurator by Klaas Nienhuis',
      banner: 'footjoypoc/assets/US-OW-156.png',
      menu: [
        {
          name: 'liquid',
          title: 'Personalize',
          icon: 'mdi-palette',
          options: [
            {material: 'jacket_US_OW_156'},
            {liquid: 'jacket_US_OW_156'}
          ]
        },
      ],
      player: {template: 'avoidunder', payload: {}},
      background: {template: 'white', payload: {}},
      environment: {template: 'softvignette', payload: {}},
      postprocessing: {template: 'ssao', payload: {}},
      lighting: {template: 'footjoy_2point', payload: {}},
      annotations: [
      ]
    },
    {
      engine: 'babylon',
      scenetitle: 'US-OW-157 Jacket',
      route: 'US-OW-157',
      scenefile: 'footjoypoc/assets/geometry/US-OW-157.babylon',
      view: 'start',
      header: 'Footjoy POC',
      subheader: 'Configurator by Klaas Nienhuis',
      banner: 'footjoypoc/assets/US-OW-157.png',
      menu: [
        {
          name: 'liquid',
          title: 'Personalize',
          icon: 'mdi-palette',
          options: [
            {material: 'jacket_US_OW_157'},
            {liquid: 'jacket_US_OW_157'}
          ]
        },
      ],
      player: {template: 'avoidunder', payload: {}},
      background: {template: 'white', payload: {}},
      environment: {template: 'softvignette', payload: {}},
      postprocessing: {template: 'ssao', payload: {}},
      lighting: {template: 'footjoy_2point', payload: {}},
      annotations: [
      ]
    }
  ]
}

export default project
