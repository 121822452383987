import _base from './_base-module'

const state = {
  drawer: false,
  bottomnav: false,
  cardcommercialbig: true,
  projectsdialog: false,
  hasWebgl: true,
  inspector: false,
  textureloading: false,
  headless: false
}

const getters = {
  getState (state) {
    return state
  },
  getDrawer (state) {
    return state.drawer
  },
  getItemByName: (state) => (name) => {
    return state[name]
  }  
}

const actions = {
  fetchData ({ commit }, {ui}) {
    commit('setState', ui)
  },
  openDrawer({ commit }) {
    commit('setDrawer', true)
  },
  closeDrawer({ commit }) {
    commit('setDrawer', false)
  }
}

const mutations = {
  setState(state, data) {
    state.inspector = data.inspector
    state.infopanel = data.infopanel
    state.bottomnav = data.bottomnav
    state.projecttitle = data.projecttitle
    state.headerlogo = data.headerlogo
    state.headless = data.headless
  },
  setDrawer(state, data) {
    state.drawer = data
  },
  setHeadless(state, data) {
    state.headless = data
  },
  setHasWebgl(state, data) {
    state.hasWebgl = data
  },
  updateItemByName (state, payload) {
    state[payload.key] = payload.value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
