<script>
import environmentmenu from './environment-menu'
import configure from '@/tools/configurator/configure'

export default {
  name: 'environmentoption',
  components: {
    environmentmenu
  },
  data: () => ({
    show: false,
    labelexposure: 1.02
  }),  
  props: {
    optionname: String
  },
  computed: {
    environment () {
      return this.$store.getters['environment/getItemByName'](this.optionname)
    },
    currentOption () {
      let current = this.environment.options.find(item => item.name === this.environment.visible)
      // this.exposure = current.payload.exposure
      return current
    },
    exposure: {
      get: function () {
        return this.currentOption.payload.exposure
      },
      set: function (newValue) {
        this.$store.dispatch('environment/setCurrentOptionPayloadProperty', {environment: this.environment, option: this.currentOption, property: 'exposure', value: newValue})
        configure.SetEnvironment({exposure: newValue})
      }
    },
    rotation: {
      get: function () {
        return this.currentOption.payload.rotation * (180/Math.PI)
      },
      set: function (newValue) {
        this.$store.dispatch('environment/setCurrentOptionPayloadProperty', {environment: this.environment, option: this.currentOption, property: 'rotation', value: newValue * (Math.PI/180)})
        configure.SetEnvironment({rotation: newValue * (Math.PI/180)})
      }
    },
    lightIntensity: {
      get: function () {
        return this.currentOption.payload.lightIntensity
      },
      set: function (newValue) {
        this.$store.dispatch('environment/setCurrentOptionPayloadProperty', {environment: this.environment, option: this.currentOption, property: 'lightIntensity', value: newValue})
        configure.SetEnvironment({lightIntensity: newValue})
      }
    }
  },
  methods: {
  }  
}
</script>

<template>
  <v-card>
    <div class="d-flex flex-no-wrap">
      <v-list-item-avatar 
        v-if="environment.swatch" 
        :color="environment.swatch" 
        class="elevation-4 ma-2"
        left
        tile
        size="80"
      />
      <v-list-item-avatar 
        v-if="environment.icon" 
        class="elevation-4 ma-2"
        left
        tile
        size="80"
      >
        <v-img 
          :src="environment.icon"
        />
      </v-list-item-avatar> 
      <div>
        <v-card-title>
          {{ environment.title }}
        </v-card-title>

        <v-card-subtitle
          class="black--text"
        >
          {{ environment.currentTitle }}
        </v-card-subtitle>
      </div>
    </div>

    <v-slider
      label="Exposure"
      :min="0"
      :max="5"
      step=0.01
      v-model="exposure"
      class="black--text mt-6 ml-3"
      thumb-label="always"
    >
    </v-slider>          
    <v-slider
      label="Rotation"
      :min="0"
      :max="360"
      step=1
      v-model="rotation"
      class="black--text mt-6 ml-3"
      thumb-label="always"
    >
    </v-slider>          
    <v-slider
      label="Shadow"
      :min="0"
      :max="10"
      step=0.1
      v-model="lightIntensity"
      class="black--text mt-6 ml-3"
      thumb-label="always"
    >
    </v-slider>          
    <v-card-actions>
      <v-btn
        text
        @click="show = !show"
      >
        Environment list
        <v-icon 
          right
        >
          {{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        </v-icon>
      </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>
        <environmentmenu :options="environment.options" :name="environment.name"/>
      </div>
    </v-expand-transition>

  </v-card>
</template>