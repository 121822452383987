import scenetools from './scene'
import {
  ArcRotateCamera,
  Vector3,
  Tools
} from '@babylonjs/core';

const buildCamera = function () {

  // Creates, angles, distances and targets the camera
  //Y and Z are swapped between max and babylon
  //Use scene.activeCamera to get the current cam props
  const cam = new ArcRotateCamera("Camera", -2.1,1.1, 10, new Vector3(0,0,0), scenetools.scene);
  //Set up the camera
  cam.inertia = 0.75;
  cam.angularSensibilityX = 600;
  cam.angularSensibilityY = 600;
  cam.panningSensibility = 0; //disable panning
  cam.wheelPrecision = 0.1;
  cam.wheelDeltaPercentage = 0.02;
  // cam.lowerRadiusLimit = 1000;
  // cam.upperRadiusLimit = 2500;
  cam.upperBetaLimit = 1.75;
  //these two are important for z fighting
  cam.maxZ = 60
  cam.minZ = 1

  // This attaches the camera to the canvas
  cam.attachControl(scenetools.canvas, true, false);
  scenetools.scene.activeCameras = [cam]
}

const editActiveCamera = function (payload) {
  let cam = scenetools.scene.activeCameras[0]

  if (payload.maxZ) cam.maxZ = payload.maxZ
  if (payload.minZ) cam.minZ = payload.minZ
  if (payload.target) cam.setTarget(Vector3.FromArray(payload.target))

  if (payload.alpha) cam.alpha = Tools.ToRadians(payload.alpha)
  if (payload.beta) cam.beta = Tools.ToRadians(payload.beta)
  if (payload.radius) cam.radius = payload.radius
  if (payload.lowerRadiusLimit) cam.lowerRadiusLimit = payload.lowerRadiusLimit
  if (payload.upperRadiusLimit) cam.upperRadiusLimit = payload.upperRadiusLimit
  if (payload.wheelPrecision) cam.wheelPrecision = payload.wheelPrecision
  if (payload.wheelDeltaPercentage) cam.wheelDeltaPercentage = payload.wheelDeltaPercentage
  if (payload.upperBetaLimit) cam.upperBetaLimit = Tools.ToRadians(payload.upperBetaLimit)
  scenetools.scene.render()
}


export default { buildCamera, editActiveCamera }
