import scenetools from './scene'
import {HDRCubeTexture} from '@babylonjs/core';
import {CubeTexture} from '@babylonjs/core';
import {Color3, Color4} from '@babylonjs/core';

import {StandardMaterial, Mesh} from '@babylonjs/core';

let theHdr;

const setupEnvironment = function (settings){

    scenetools.scene.clearColor = new Color4(settings.clearcolor[0], settings.clearcolor[1], settings.clearcolor[2], 1);
    scenetools.scene.autoClear = true
    scenetools.scene.imageProcessingConfiguration.exposure = settings.exposure;
    scenetools.scene.imageProcessingConfiguration.contrast = settings.contrast;

    if (settings.vignetteWeight) scenetools.scene.imageProcessingConfiguration.vignetteWeight = settings.vignetteWeight;
    if (settings.vignetteCameraFov) scenetools.scene.imageProcessingConfiguration.vignetteCameraFov = settings.vignetteCameraFov;
    if (settings.vignetteEnabled) scenetools.scene.imageProcessingConfiguration.vignetteEnabled = settings.vignetteEnabled;

    // scene.createDefaultSkybox(theHdr, true, 5000);

    // var theHdr = new CubeTexture.CreateFromPrefilteredData('/data/appliance/env/environment.env', sc);
    // theHdr.gammaSpace = false;
    // sc.environmentTexture = theHdr;    
}

const buildSkybox = function () {
    // let sc = scene.getScene()

    // Skybox
    var skybox = Mesh.CreateBox("skyBox", 50.0, scenetools.scene);
    skybox.flipFaces(true);
    var skyboxMaterial = new StandardMaterial("skyBox", scenetools.scene);
    // skyboxMaterial.backFaceCulling = false;
    // skyboxMaterial.twoSidedLighting = true;
    // skyboxMaterial.useEmissiveAsIllumination = true;
    // skyboxMaterial.reflectionTexture = new BABYLON.CubeTexture("/textures/skybox", scene);
    // skyboxMaterial.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE;
    // skyboxMaterial.diffuseColor = new Color3(1, 0.9, 1);
    skyboxMaterial.emissiveColor = new Color3(1, 1, 1);
    // skyboxMaterial.specularColor = new Color3(1, 1, 1);
    skyboxMaterial.disableLighting = true;
    skybox.material = skyboxMaterial;
    skybox.infiniteDistance = true;    
}
const makeEnvHdr = function (filename){
    // let sc = scene.getScene()
    theHdr = new HDRCubeTexture(process.env.BASE_URL + 'assets/' + filename, scenetools.scene, 256);
    theHdr.gammaSpace = false
}

export default { 
    makeEnvHdr, 
    setupEnvironment, 
    buildSkybox,
    get hdr() {
        return theHdr
    },    
 }
