import _base from './_base-module'
import templateData from 'ProjectData/template'
import libraryData from 'ProjectData/library'

const state = {
  all: []
}

const getters = {
  ..._base.getters
}

function uuidv4() {
  // let cryptoObj = window.crypto || window.msCrypto;
  // return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
  //   (c ^ cryptoObj.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  // );
  return Math.floor(Math.random() * 64000)
}

function assemblePayload(currentPayload, libraryName) {
  let payload = currentPayload
  if (libraryName) {
    let lib = libraryData.transform.find(item => item.name === libraryName)
    if (lib) {
      payload = {...lib.payload, ...currentPayload}
    }
  }
  return payload
}

function getBasedonLabel(basedonName) {
  if (basedonName) {
    let basedon = templateData.transform.find(item => item.name === basedonName)
    if (basedon) {
      return basedon.label
    }
  }
  return null
}

function buildTransformFromMenuoption(option) {
  let transform = templateData.transform.find(item => item.name === option.transform)

  // if (environment.type === 'static') {
    for (let option of transform.options) {
      option.id = uuidv4()
      option.payload = assemblePayload(option.payload, option.library)
      const basedonLabel = getBasedonLabel(option.library)
      option.label = option.label ? option.label : basedonLabel
      if (option.icon) {
        option.icon = (process.env.BASE_URL + option.icon)
      }
    }
  // }
  
  if (transform.default === undefined) {
    transform.default = transform.options[0].name
  }
  return transform
}

const actions = {
  setCurrentTransform ({ commit }, {transform, option}) {
    commit('updateSelectedTransform', {transform: transform, option: option})
  },
  setCurrentOptionPayloadProperty ({commit}, {transform, option, property, value}) {
    commit('setCurrentOptionPayloadProperty', {transform: transform, option: option, property: property, value: value})
  },
  fetchData ({ commit }, {menu}) {

    commit('clearAll')
    // Search the menu for transforms
    for (let menuitem of menu) {
      if (menuitem.options) {
        for (let option of menuitem.options) {
          if (option.transform) {
            const transform = buildTransformFromMenuoption(option)
            let currentoption = transform.options.find(item => item.name === transform.default)
            commit('addTransform', transform)
            commit('updateSelectedTransform', {transform: transform, option: currentoption})     
          }
        }
      }
      if (menuitem.submenu) {
        for (let submenuitem of menuitem.submenu) {
          if (submenuitem.options) {
            for (let option of submenuitem.options) {
              if (option.transform) {
                const transform = buildTransformFromMenuoption(option)
                let currentoption = transform.options.find(item => item.name === transform.default)
                commit('addTransform', transform)
                commit('updateSelectedTransform', {transform: transform, option: currentoption})
              }  
            }
          }
        }
      }
    }
  }
}

const mutations = {
  ..._base.mutations,
  clearAll (state) {
    state.all = []
  },
  addTransform (state, transform) {
    state.all.push(transform)
  },
  setCurrentOptionPayloadProperty (state, payload) {
    state.all = state.all.map(item => {
      if (item.name === payload.transform.name) {
        console.log('item.name', item.name)
        item.options = item.options.map(option => {
          if (option.name === payload.option.name) {
            console.log('option.name', option.name)
            option.payload[payload.property] = payload.value
          }
          return option
        })
      } return item
    })
  },
  updateSelectedTransform (state, payload) {
    state.all = state.all.map(item => {
      if (item.name === payload.transform.name) {
        item.currentTitle = payload.option.title
        item.swatch = payload.option.swatch
        item.label = payload.option.label
        item.icon = payload.option.icon
        item.default = payload.option.name
      } return item
    })
  }, 
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
