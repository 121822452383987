<script>

import menuGroup from './menugroup'

export default {
    name: 'configuratordrawer',
    components: {
        menuGroup,
    },
    data: () => ({
        modelroute: '/models'
    }),
    computed: {
        menu () {
            return this.$store.getters['menu/getAll']
        },
    },
    methods: {
    }   
}
</script>

<template>
    <v-list
        color="card"
        class="pa-0"
    >
        <div 
            v-for="item in menu" 
            :key="item.name"
        >
            <menuGroup 
                :menu="item"
            />
        </div>
        
        <v-list-item 
            router 
            :to="modelroute"
        >
            <v-list-item-action>
                <v-icon>
                    mdi-view-dashboard
                </v-icon>
            </v-list-item-action>
            <v-list-item-content>
                <v-list-item-title
                    class="title"
                >
                    Pick garment
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>    
    </v-list>
</template>