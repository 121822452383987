import scenetools from './scene'
import liquidtools from './liquid'
import {StandardMaterial} from '@babylonjs/core';
import {PBRSpecularGlossinessMaterial} from '@babylonjs/core';
import {PBRMaterial} from '@babylonjs/core';
import {Color3} from '@babylonjs/core';
import {Texture} from '@babylonjs/core';
import {DynamicTexture} from '@babylonjs/core';

import LiquiFire from '@/classes/liquifire'
import store from 'Store'
import router from '../../router'
import configurator from 'Components/configurator'

let sceneMaterials = {}

const getMtl = function (id) {
  return sceneMaterials[id]
}

const getSceneMtl = function (name) {
  return scenetools.scene.getMaterialByName(name)
}

const clearScenematerials = function () {
  sceneMaterials = {}
}

//build the materials from the data file
const setupMaterials = function (materialData, hdrTexture){
  materialData.forEach(data => {      
    if (data.type === 'single' || data.type === 'overrideexclude') {
      data.options.forEach(option => {
        const newMtl = createMaterial(option.payload, option.id, hdrTexture)
        sceneMaterials[option.id] = newMtl
      });
    }
  });
}

const createMaterial = function (payload, id, hdrTexture) {
  let newMtl = null;
  //Get the material type from the data
  //Some channels are only relevant to certian types of mateials. If relevant, they get the hdrTexture for
  //reflection or environment
  switch (payload.type){
    case "PBRSpecularGlossinessMaterial":
      newMtl = new PBRSpecularGlossinessMaterial(id, scenetools.scene);
      newMtl.environmentTexture  = hdrTexture;
      newMtl.backFaceCulling = false;
    break;
    case "PBRMaterial":
      newMtl = new PBRMaterial(id, scenetools.scene);
      newMtl.reflectionTexture  = hdrTexture;
      newMtl.backFaceCulling = false;
    break;
  }

  // var boxMaterial = new StandardMaterial(id, sc);
  // boxMaterial.diffuseColor = new Color3(0.8,0.6,0.8);
  // boxMaterial.specularColor = Color3.Black();

  applyMaterialSettings(newMtl, payload);

  return newMtl
  // return boxMaterial
}

const applyMaterialSettings = function (material, payload){
  console.log('material', material)
  console.log('payload', payload)
  if (material instanceof PBRSpecularGlossinessMaterial){
    const channels = [
      'diffuseTexture',
      'normalTexture',
      'specularGlossinessTexture'
    ]

    let texturePromises = []
    channels.forEach(channel => {
      if (payload[channel]) {
        texturePromises.push(applyTextureSettings(store.state.liquifire, material, payload[channel], channel))
      }
    })

    //process all texture promises in one go. this avoids the material resetting to
    //white wilre another texture is loading
    Promise.all(texturePromises).then((values) => {
      values.forEach(value => {
        if (value) material[value.textureName] = value.texture
      });
      hideTextureLoadingScreen()
    })

    applySetting(material, payload, 'glossiness');
    applyColor(material, payload, 'specularColor');
    applyColor(material, payload, 'diffuseColor');
    
  }
  
  // if (material instanceof PBRMaterial){
  //   applyTextureSettings(scenetools.scene, material,settings,"albedoTexture")
  //   applyTextureSettings(scenetools.scene, material,settings,"bumpTexture")
  //   applyTextureSettings(scenetools.scene, material,settings,"metallicTexture")
  //   applyTextureSettings(scenetools.scene, material,settings,"reflectivityTexture")
    
  //   applyColor(material, settings, 'albedoColor');
  //   applySetting(material, settings, 'roughness');
  //   applySetting(material, settings, 'metallic');
  // }
}

// take texturesettings from a data source and apply them to a material
// the structure of the settings and material need to match
const applyTextureSettings = function (
  liquiFireConfiguration,
  material,
  materialConfiguration,
  textureName
) {
  return new Promise(resolve => {
    if (materialConfiguration) {
      if (materialConfiguration.level === null) materialConfiguration.level = 1

      // default setting which makes sense when the models come from 3dsMax
      if (materialConfiguration.invertY === null) materialConfiguration.invertY = true
    }

    // don't want to overwrite the default material
    if (materialConfiguration === undefined) resolve()

    if (materialConfiguration.liquid) {
      console.group('LIQUIFIRE TEXTURE')
      let liquifireObject = liquidtools.parseQuery()
      console.log('liquifireObject', liquifireObject)
      if (liquifireObject) {
        store.commit('ui/setHeadless', true)
        store.dispatch('ui/closeDrawer')
        console.log('Liquid object created from query')
      } else {

        liquifireObject = createLiquifireFromState(
          liquiFireConfiguration,
          materialConfiguration.liquid,
        )
      }

      makeTextureFromLiquiFire(
        liquifireObject,
        materialConfiguration.invertY
      ).then(liquidtexture => {
        console.log('Liquid texture loaded')
        console.groupEnd()
        resolve({texture: liquidtexture, textureName: textureName})
      })

    } else if (materialConfiguration.path === null || materialConfiguration.path === undefined || materialConfiguration.path === '') {
      // if the path is explicitly absent, remove the texture
      material[textureName] = null
      resolve()
    } else {
      // add a callback to render the scene once the texture is ready
      let filetexture
      const onLoad = () => {
        filetexture.level = materialConfiguration.level
        resolve({texture: filetexture, textureName: textureName})      
      }

      filetexture = new Texture(
        (process.env.BASE_URL + materialConfiguration.path),
        scenetools.scene,
        undefined,
        materialConfiguration.invertY,
        undefined,
        onLoad
      )
    }
  })
}

const createLiquifireFromState = function (liquiFireConfiguration, materialLiquidPayload) {
  // liquiFireConfiguration: the basic liquifire setup for this project. Contains url, chain address, sink and so on. This is constant for this project
  // materialLiquidPayload: the liquid contents of a material

  const liquiFire = new LiquiFire(liquiFireConfiguration)
  Object.entries(materialLiquidPayload).forEach(([key, value]) => { liquiFire.setOption(key, value) })
  return liquiFire
}


const makeTextureFromLiquiFire = function (liquifireObject, invertY) {
  // Use a liquifire object, get its url and create a babylon texture with that url
  if (invertY === null) invertY = true

  return new Promise(resolve => {
    showTextureLoadingScreen()
    // eslint-disable-next-line prefer-const
    let theTexture

    // the liquifire texture might take a bit to generate. This promise avoids removing
    // the existing texture with the new one, before it's fully formed.
    const onLoad = () => { resolve(theTexture) }

    console.log('texture url:', liquifireObject.textureUrl)
    theTexture = new Texture(liquifireObject.textureUrl, scenetools.scene, undefined, invertY, undefined, onLoad)
  })
}



const applyColor = function (material, settings, settingName) {
  if (settings[settingName] == undefined) {
    //don't change anything. We don't want to overwrite the default setting
  }else if (settings[settingName] == null) {
    //The setting is explicitly set to null, revert to a default value
    material[settingName] = new Color3(1,1,1);
  }else {
    material[settingName] = new Color3(settings[settingName][0],settings[settingName][1],settings[settingName][2]);
  }
}    
const applySetting = function (material, settings, settingName) {
  if (settings[settingName] == undefined) {
    //don't change anything. We don't want to overwrite the default setting
  }else if (settings[settingName] == null) {
    //The setting is explicitly set to null, revert to a default value
    material[settingName] = 1
  }else {
    material[settingName] = settings[settingName]
  }
}

//Apply a material to objects with a specific name
const applyMaterialByNames = function (objectNames, material) {
  objectNames.forEach(name => {
    const objects = scenetools.getMesh(name)
    objects.forEach(obj => {
      obj.material = material
    });
  })
}

const createTextTexture = function (textOption) {
  let engraving = new DynamicTexture(textOption.name, textOption.payload.size, scenetools.scene);
  let ctx = engraving.getContext();
  let font = textOption.payload.font;
  ctx.font = font;
  let textWidth = ctx.measureText(textOption.text).width;
  const xpos = textOption.payload.size.width/2 - textWidth/2
  engraving.drawText(textOption.text, xpos, 350, font, textOption.payload.textColor, textOption.payload.canvasColor, true, true);
  return engraving
}

const showTextureLoadingScreen = function () {
  store.commit('ui/updateItemByName', {key: 'textureloading', value: true})
}

const hideTextureLoadingScreen = function () {
  store.commit('ui/updateItemByName', {key: 'textureloading', value: false})
}

const applyLiquidFromMessage = function (route, headless, liquidOptionName, url) {
  store.commit('ui/updateItemByName', {key: 'headless', value: headless})
  store.commit('ui/updateItemByName', {key: 'drawer', value: !headless})

  // const currentRoute = store.state.scenes.currentScene.route
  // if (route !== currentRoute) {
  //   console.log('route', route)
  //   console.log('router', router)
  //   router.push({name:'scene', params: { id: route}}).then(() => {
  //     console.log('LOADED BOI')
  //     window.location.reload()
  //   })
  //   const scene = store.getters['scenes/getSceneByRoute'](route) 
  //   store.dispatch('scenes/setCurrentScene', {scene: scene})    
  // }
  // completely ignore setting the state. Just take the texture url, request it and apply it
  // to a material
  let myliquid = store.getters['liquid/getItemByName'](liquidOptionName)
  console.log('myliquid', myliquid)
  let mymaterial = store.getters['material/getItemByName'](myliquid.materialname)
  let mymaterialoption = mymaterial.options.find(option => option.name === myliquid.materialoption)
  let myscenematerial = getMtl(mymaterialoption.id)

  makeTextureFromLiquiFire({textureUrl: url}).then(liquidtexture => {
    if (liquidtexture) myscenematerial['diffuseTexture'] = liquidtexture
    hideTextureLoadingScreen()
  })
}

window.applyLiquidFromMessage = applyLiquidFromMessage

export default { 
  setupMaterials, 
  getMtl, 
  getSceneMtl, 
  applyMaterialByNames, 
  createTextTexture, 
  applyMaterialSettings,
  clearScenematerials,
  applyLiquidFromMessage
}
