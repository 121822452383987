<script>
import configure from '@/tools/configurator/configure'

export default {
  name: 'materialswappablemenu',
  props: {
    options: Array,
    name: String
  },
  computed: {
    currentMaterial () {
      return this.$store.getters['materialswappable/getItemByName'](this.name)
    },
    swappees () {
      const swappees = this.options.map(element => {
        return this.$store.getters['materialswappable/getItemByName'](element)
      });
      return swappees
    }
  },
  methods: {
    setMaterial (materialswappable, option) {
      this.$store.dispatch('materialswappable/setCurrentMaterial', {material: {name: materialswappable.name, visible: option.name}, option: null})

      const mltOption = option.options.find(element => element.name === option.visible)
      //add additional payload
      let payload = mltOption.payload
      if (materialswappable.payload) {
        payload = {...payload, ...materialswappable.payload}
      }
      configure.SetMaterialOption(materialswappable, {payload: payload})
    },    
  }  
}
</script>

<template>
<div>
  <v-sheet
    class="d-flex flex-wrap pa-2"
    color="white"
  >
    <v-sheet
      v-for="(option, index) in swappees" 
      :key="index" 
    >
      <v-avatar
        class="elevation-5 mr-2"
        tile
        v-if="option.swatch" 
        :color="option.swatch" 
        @click="setMaterial(currentMaterial, option)"
        size=60
      >
      </v-avatar>
    </v-sheet>
  </v-sheet>
</div>
</template>