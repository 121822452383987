const library = {
  materials: [
    {
      name: 'empty',
      payload: {
      }
    }
  ],
  player: [
    {
      name: 'avoidunder',
      payload: {
        orbit_constraint_pitch_down: -0.1,
        orbit_constraint_pitch_up: 1.5,
        orbit_constraint_pan: 0,
        transparent: 0
      }
    }
  ],
  postprocessing: [
    {
      name: 'empty',
      payload: {
      }
    }
  ],
  environment: [
    {
      name: 'empty',
      payload: {
      }
    }
  ],
  lighting: [
    {
      name: 'empty',
      payload: {
      }
    }
  ],
  background: [
    {
      name: 'white',
      payload: {
        enable: 'color',
        color: [1, 1, 1]
      }
    },
    {
      name: 'lightgrey',
      payload: {
        enable: 'color',
        color: [0.9, 0.9, 0.9]
      }
    },
    {
      name: 'transparent',
      payload: {
        enable: 'none'
      }
    }
  ]
}

export default library
