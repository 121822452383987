class Liquid {
  // title
  // name
  // materialname
  // materialoption
  // channel
  // prodID
  // options
  // keygroups
  // relations

  constructor(data) {
    this.title = data.title
    this.name = data.name
    this.materialname = data.materialname
    this.materialoption = data.materialoption
    this.channel = data.channel
    this.prodID = data.prodID
    this.keygroups = data.keygroups
    this.options = data.options
    this.relations = data.relations
  }
}

export default Liquid