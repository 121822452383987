<script>
import configure from '@/tools/configurator/configure'

export default {
  name: 'materialmenu',
  props: {
    options: Array,
    name: String
  },
  computed: {
    currentMaterial () {
      return this.$store.getters['material/getItemByName'](this.name)
    },
  },
  methods: {
    setMaterial (material, option) {
      // using the store from the import helps keep the ui updated even when it's
      // not visible. e.g. when it's triggered from the states menu
      this.$store.dispatch('material/setCurrentMaterial', {material: material, option: option})
      configure.SetMaterialOption(material, option)
    },
    getIcon (option) {
      console.log('option', option)
      if (option.icon) {
        return (process.env.BASE_URL + option.icon)
      } else {
        return null
      }
    },      
  }  
}
</script>

<template>
<div>
  <v-sheet
    class="d-flex flex-wrap pa-2"
    elevation="0"
    color="card"
  >
    <v-sheet
      v-for="(option, index) in options" 
      :key="index" 
    >
      <v-avatar
        tile
        v-if="option.swatch" 
        :color="option.swatch" 
        @click="setMaterial(currentMaterial, option)"
        size=62
      >  
      </v-avatar>
      
      <v-avatar
        tile
        v-if="option.icon" 
        @click="setMaterial(currentMaterial, option)"
        size=62
      >
        <img
          :src="getIcon(option)"
        >      
      </v-avatar>
    </v-sheet>
  </v-sheet>
</div>
</template>