<script>
import configure from '@/tools/configurator/configure'
import visibilitygroupmenu from './visibilitygroup-menu'

export default {
  name: 'visibilitygroupoption',
  components: {
    visibilitygroupmenu
  },
  props: {
    optionname: String
  },
  computed: {
    theGroup () {
      return this.$store.getters['visibilitygroup/getItemByName'](this.optionname)
    },
    icon () {
      if (this.theGroup.icon) {
        return (process.env.BASE_URL + this.theGroup.icon)
      } else {
        return null
      }
    },    

  },
  methods: {
    highlight () {
      // just adding the click event adds a highlight to a menu item
    }
  }  
}
</script>

<template>
<!-- <v-menu :close-on-content-click="false" offset-y max-height=350>
  <template v-slot:activator="{ on }">
    <v-list-item @click="highlight" v-on="on">
      <v-list-item-content two-line>
        <v-list-item-title v-text="theGroup.title"></v-list-item-title>
        <v-list-item-subtitle>{{ theGroup.currentTitle }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-avatar v-if="theGroup.swatch" :style="theGroup.swatch"/>
      <v-icon >mdi-menu-right</v-icon>
    </v-list-item>
  </template>
  <visibilitygroupmenu :options="theGroup.options" :name="theGroup.name"/>  
</v-menu> -->



  <v-list-group
    v-if="theGroup && theGroup.options.length > 1"
    color='accent'
  >
    <template v-slot:activator>
      <v-list-item-avatar 
        v-if="theGroup.swatch" 
        :color="theGroup.swatch" 
        class="elevation-4"
        left
      />
      <v-list-item-avatar v-if="icon" class="elevation-4">
        <v-img :src="icon"/>
      </v-list-item-avatar>      

      <v-list-item-content two-line>
          <v-list-item-title v-text="theGroup.title"></v-list-item-title>
          <v-list-item-subtitle>{{ theGroup.currentTitle }}</v-list-item-subtitle>
      </v-list-item-content>
    </template>
      <v-divider></v-divider>
      <visibilitygroupmenu :options="theGroup.options" :name="theGroup.name"/>

  </v-list-group>

</template>