<script>
import materialoption from './material-option'
import materialswappableoption from './materialswappable-option'
import viewoption from './view-option'
import presetoption from './preset-option'
import envoption from './environment-option'
import visibilitysingleoption from './visibilitysingle-option'
import visibilitygroupoption from './visibilitygroup-option'
import liquidoption from './liquid-option'
import transformoption from './transform-option'
import labelcard from './label-card'
import textcard from './text-card'

export default {
  name: 'menuOptions',
  components: {
    materialoption,
    materialswappableoption,
    liquidoption,
    viewoption,
    envoption,
    presetoption,
    visibilitygroupoption,
    visibilitysingleoption,
    transformoption,
    labelcard,
    textcard
  },  
  props: {
    menu: Object
  },
  computed: {
    icon () {
      return this.menu.icon
    }
  },
  methods: {
    highlight () {
      // just adding the click event adds a highlight to a menu item
    }
  }  
}
</script>

<template>
<div v-if="menu.options">
  <v-list
    color="card"
  >
  <div v-for="(option, index) in menu.options" :key="index">
    <liquidoption v-if="option.liquid" :optionname="option.liquid"/>
    <materialoption v-if="option.material" :optionname="option.material"/>
    <materialswappableoption v-if="option.materialswappable" :optionname="option.materialswappable"/>
    <viewoption v-if="option.view" :optionname="option.view"/>
    <envoption v-if="option.environment" :optionname="option.environment"/>
    <labelcard v-if="option.label" :optionname="option.label"/>
    <textcard v-if="option.text" :optionname="option.text"/>
    <visibilitysingleoption v-if="option.visibilitysingle" :optionname="option.visibilitysingle"/>
    <visibilitygroupoption v-if="option.visibilitygroup" :optionname="option.visibilitygroup"/>
    <transformoption v-if="option.transform" :optionname="option.transform"/>
    <presetoption v-if="option.preset" :optionname="option.preset"/>
  </div>
  </v-list>
</div>    
</template>