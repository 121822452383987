import _base from './_base-module'
import templateData from 'ProjectData/template'
import Liquid from '@/classes/Liquid'

const Model = Liquid

const state = {
  all: []
}

const getters = {
  ..._base.getters
}


const actions = {
  fetchData ({ commit }) {
    commit('clearAll')
    templateData.liquid && templateData.liquid.forEach(item => {
      commit('addItem', item)
    })
  },
}

const mutations = {
  addItem (state, item) {
    const liquid = new Model(item)
    state.all.push(liquid)
  },
  clearAll (state) {
    state.all = []
  },
  setProductId (state, payload) {
    state.all = state.all.map(element => {
      if (element.name === payload.liquidname) {
        element.prodID = payload.productid
      }
      return element
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
