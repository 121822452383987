const template = {
  materialoptions: [
    {
      name: 'shirt_US_F20_05',
      title: 'US-F20-05 Polo',
      type: 'single',
      object: [
        'US_F20_05_sleeves',
        'US_F20_05_buttons',
        'US_F20_05_labels',
        'US_F20_05_placket',
        'US_F20_05_collar_stand',
        'US_F20_05_collar',
        'US_F20_05_body_back',
        'US_F20_05_body_front',
        'US_F20_05_back_top',
      ],
      visible: 'shirt_US_F20_05',
      options: [
        {
          name: 'shirt_US_F20_05',
          title: 'Shoe',
          basedon: 'shoe',
          payload: {
            glossiness: 0.2,
            specularColor: [0.2, 0.2, 0.2],
            diffuseTexture: {
              liquid: {
                prodID: '26366C'
              },
            },
            normalTexture: {
              path: 'footjoypoc/assets/textures/US-F20-05-normal-4k.png',
              level: 5.2
            },
            specularGlossinessTexture: {
              path: 'footjoypoc/assets/textures/US_F20_05_specgloss.png',
              level: 1.0
            }
          },
          icon: '/data/appliance/textures/ConcreteNew0012_2_S.jpg'
        }
      ]
    },
    {
      name: 'shirt_US_F20_08',
      title: 'US-F20-08 Polo',
      type: 'single',
      object: [
        'US_F20_08_body',
        'US_F20_08_buttons',
        'US_F20_08_collar',
        'US_F20_08_placket',
        'US_F20_08_pocket',
        'US_F20_08_sleeves',
      ],
      visible: 'shirt_US_F20_08',
      options: [
        {
          name: 'shirt_US_F20_08',
          title: 'Shoe',
          basedon: 'shoe',
          payload: {
            glossiness: 0.2,
            specularColor: [0.2, 0.2, 0.2],
            diffuseTexture: {
              liquid: {
                prodID: '26374C'
              },
            },
            normalTexture: {
              path: 'footjoypoc/assets/textures/US-F20-08-NORMAL-from-diffuse.png',
              level: 5.2
            },
            specularGlossinessTexture: {
              path: 'footjoypoc/assets/textures/US_F20_05_specgloss.png',
              level: 1.0
            }
            
          },
          icon: '/data/appliance/textures/ConcreteNew0012_2_S.jpg'
        }
      ]
    },
    {
      name: 'shirt_US_F20_10',
      title: 'Shirt material',
      type: 'single',
      object: [
        'US_F20_10_back_top_inside',
        'US_F20_10_body_back',
        'US_F20_10_body_front',
        'US_F20_10_button',
        'US_F20_10_collar',
        'US_F20_10_collar_stand',
        'US_F20_10_labels',
        'US_F20_10_placket_inside',
        'US_F20_10_placket_outside',
        'US_F20_10_sleeves',
      ],        
      visible: 'shirt_US_F20_10',
      options: [
        {
          name: 'shirt_US_F20_10',
          title: 'Shoe',
          basedon: 'shoe',
          payload: {
            glossiness: 0.2,
            specularColor: [0.2, 0.2, 0.2],
            diffuseTexture: {
              liquid: {
                prodID: '26380C'
              },
            },
            normalTexture: {
              path: 'footjoypoc/assets/textures/US-F20-10-normal-4k.png',
              level: 5.2
            },
            specularGlossinessTexture: {
              path: 'footjoypoc/assets/textures/US_F20_05_specgloss.png',
              level: 1.0
            }
          },
          icon: '/data/appliance/textures/ConcreteNew0012_2_S.jpg'
        }
      ]
    },
    {
      name: 'shirt_US_F20_13',
      title: 'Shirt material',
      type: 'single',
      object: [
        'US_F20_13_buttons',
        'US_F20_13_labels',
        'US_F20_13_placket',
        'US_F20_13_sleeves',
        'US_F20_13_collar_stand',
        'US_F20_13_collar',
        'US_F20_13_back_top',
        'US_F20_13_body_back',
        'US_F20_13_body_front',
      ],
      visible: 'shirt_US_F20_13',
      options: [
        {
          name: 'shirt_US_F20_13',
          title: 'Shoe',
          basedon: 'shoe',
          payload: {
            glossiness: 0.2,
            specularColor: [0.2, 0.2, 0.2],
            diffuseTexture: {
              liquid: {
                prodID: '26387C'
              },  
            },
            normalTexture: {
              path: 'footjoypoc/assets/textures/US-F20-13-normal-4k.png',
              level: 5.2
            },
            specularGlossinessTexture: {
              path: 'footjoypoc/assets/textures/US_F20_05_specgloss.png',
              level: 1.0
            }
          },
          icon: '/data/appliance/textures/ConcreteNew0012_2_S.jpg'
        }
      ]
    },
    {
      name: 'shirt_US_F20_09',
      title: 'Shirt material',
      type: 'single',
      object: [
        'US_F20_05_sleeves',
        'US_F20_05_buttons',
        'US_F20_05_labels',
        'US_F20_05_placket',
        'US_F20_05_collar_stand',
        'US_F20_05_collar',
        'US_F20_05_body_back',
        'US_F20_05_body_front',
        'US_F20_05_back_top',
      ],
      visible: 'shirt_US_F20_09',
      options: [
        {
          name: 'shirt_US_F20_09',
          title: 'Shoe',
          basedon: 'shoe',
          payload: {
            glossiness: 0.2,
            specularColor: [0.2, 0.2, 0.2],
            diffuseTexture: {
              liquid: {
                prodID: '26377C'
              },
            },
            normalTexture: {
              path: 'footjoypoc/assets/textures/US-F20-09-normal-4k.png',
              level: 5.2
            },
            specularGlossinessTexture: {
              path: 'footjoypoc/assets/textures/US_F20_05_specgloss.png',
              level: 1.0
            }

          },
          icon: '/data/appliance/textures/ConcreteNew0012_2_S.jpg'
        }
      ]
    },
    {
      name: 'jacket_US_OW_156',
      title: 'jacket material',
      type: 'single',
      object: [
        'Front_5',
        'Object001',
        'zipper_puller_bottom',
        'zipper_puller_top',
        'zipper_slider',
      ],
      visible: 'jacket_US_OW_156',
      options: [
        {
          name: 'jacket_US_OW_156',
          title: 'Shoe',
          basedon: 'shoe',
          payload: {
            glossiness: 0.15,
            specularColor: [0.005, 0.005, 0.005],
            diffuseTexture: {
              liquid: {
                prodID: '25160C'
              },              
            },
            normalTexture: {
              path: 'footjoypoc/assets/textures/US-OW-156-normal-4k.png',
              level: 5.2
            },
          },
          icon: '/data/appliance/textures/ConcreteNew0012_2_S.jpg'
        }
      ]
    },
    {
      name: 'jacket_US_OW_157',
      title: 'jacket material',
      type: 'single',
      object: [
        'zipper_pocket_topright_puller',
        'zipper_pocket_topright_slider',
        'zipper_pocket_left_puller',
        'zipper_pocket_left_slider',
        'zipper_pocket_right_puller',
        'zipper_pocket_right_slider',
        'zipper_main_knot',
        'zipper_main_puller_bottom',
        'zipper_main_puller_top',
        'zipper_main_slider',
        'US_OW_157_zipper_teeth',
        'US_OW_157_rings',
        'US_OW_157_zipper_line',
        'US_OW_157_collar',
        'US_OW_157_body_back',
        'US_OW_157_body_front',
        'US_OW_157_sleeves',
      ],
      visible: 'jacket_US_OW_157',
      options: [
        {
          name: 'jacket_US_OW_157',
          title: 'Shoe',
          basedon: 'shoe',
          payload: {
            glossiness: 0.15,
            specularColor: [0.005, 0.005, 0.005],
            diffuseTexture: {
              liquid: {
                prodID: '35376C'
              },
            },
            normalTexture: {
              path: 'footjoypoc/assets/textures/US-OW-157-normal-4k.png',
              level: 5.2
            },
          },
          icon: '/data/appliance/textures/ConcreteNew0012_2_S.jpg'
        }
      ]
    },
  ],
  liquid: [
    {
      title: 'US-F20-05 Polo',
      name: 'shirt_US_F20_05',
      materialname: 'shirt_US_F20_05',
      materialoption: 'shirt_US_F20_05',
      channel: 'diffuseTexture',
      prodID: '26366C',
      keygroups: [3,5,7,9,11,13,15,17,19,21,23,25],
      options: {
        productid: {
          key: 'prodID',
          value: ['26366C','26367C','26368C'],
          default: '26366C',
        },
        location: {
          key: 0,
          value: [
            { value: 'CCB', text: 'Collar Center Back'},
            { value: 'LBS', text: 'Left Back Shoulder'},
            { value: 'LC', text: 'Left Chest'},
            { value: 'LCO', text: 'Left Collar'},
            { value: 'LFH', text: 'Left Front Hem'},
            { value: 'LS', text: 'Left Sleeve'},
            { value: 'RBS', text: 'Right Back Shoulder'},
            { value: 'RC', text: 'Right Chest'},
            { value: 'RCO', text: 'Right Collar'},
            { value: 'RFH', text: 'Right Front Hem'},
            { value: 'RS', text: 'Right Sleeve'},
            { value: 'YBS', text: 'Yoke'}
          ],
          default: 'RC',
        },
        type: {
          key: 1,
          value: [
            { value: 'BASIC', text: 'No personalization'},
            { value: 'IMG', text: 'Image upload'},
            { value: 'TXT', text: 'Text sublimation'},
            { value: 'LTR', text: 'Embroidery lettering'},
            { value: 'EMB', text: 'Artworks embroidery'}
          ],
          default: 'BASIC',
        },
        imageurl1: {
          key: 2,
          value: 'https://pixnetiq.com/acushnet/POS/Positioning-RS.jpg',
          default: 'https://pixnetiq.com/acushnet/POS/Positioning-RC.jpg',
        },
        embroideryurl: {
          key: 2,
          value: '348651/3.m/159743.dst',
          default: '348651/3.m/159743.dst',
        },
        embroiderycolors: {
          key: 3,
          value: ['m1623','m1777','m1900','m1039','m1270','m1980','m1635'],
          default: ['m1039','m1980'],
        },
        text1: {
          key: 4,
          value: 'Acushnet',
          default: '',
        },
        text2: {
          key: 5,
          value: '',
          default: '',
        },
        text3: {
          key: 6,
          value: '',
          default: '',
        },
        text4: {
          key: 7,
          value: '',
          default: '',
        },
        truetypefont: {
          key: 8,
          value: ['BriquetSub','caslonop','KabelItcTEEDem','MANGAL','VLADIMIR'],
          default: 'MANGAL',
        },
        wilcomfont: {
          key: 8,
          value: ['Athletica','Energy','Jikharev','Market','Russian Textbook'],
          default: 'Athletica',
        },
        fontcolor: {
          key: 9,
          value: ['m1623','m1777','m1900','m1039','m1270','m1980','m1635'],
          default: 'm1039',
        },
        imprinttype: {
          key: 12,
          value: ['Imprint type 1'],
          default: 'Imprint type 1',
        }
      },
      relations: {
        BASIC: ['location','type'],
        TXT: ['location','type','text1','text2','text3','text4','truetypefont','fontcolor'],
        LTR: ['location','type','text1','wilcomfont','fontcolor'],
        IMG: ['location','type','imageurl1'],
        EMB: ['location','type','embroideryurl','embroiderycolors']
      }
    },
    {
      title: 'Polo',
      name: 'shirt_US_F20_09',
      materialname: 'shirt_US_F20_09',
      materialoption: 'shirt_US_F20_09',
      channel: 'diffuseTexture',
      prodID: '26377C',
      keygroups: [3,5,7,9,11,13,15,17,19,21,23,25],
      options: {
        productid: {
          key: 'prodID',
          value: ['26377C','26378C','26379C'],
          default: '26377C',
        },
        location: {
          key: 0,
          value: [
            { value: 'CCB', text: 'Collar Center Back'},
            { value: 'LBS', text: 'Left Back Shoulder'},
            { value: 'LC', text: 'Left Chest'},
            { value: 'LCO', text: 'Left Collar'},
            { value: 'LFH', text: 'Left Front Hem'},
            { value: 'LS', text: 'Left Sleeve'},
            { value: 'RBS', text: 'Right Back Shoulder'},
            { value: 'RC', text: 'Right Chest'},
            { value: 'RCO', text: 'Right Collar'},
            { value: 'RFH', text: 'Right Front Hem'},
            { value: 'RS', text: 'Right Sleeve'},
            { value: 'YBS', text: 'Yoke'}
          ],
          default: 'RC',
        },
        type: {
          key: 1,
          value: [
            { value: 'BASIC', text: 'No personalization'},
            { value: 'IMG', text: 'Image upload'},
            { value: 'TXT', text: 'Text sublimation'},
            { value: 'LTR', text: 'Embroidery lettering'},
            { value: 'EMB', text: 'Artworks embroidery'}
          ],
          default: 'BASIC',
        },
        imageurl1: {
          key: 2,
          value: 'https://pixnetiq.com/acushnet/POS/Positioning-RS.jpg',
          default: 'https://pixnetiq.com/acushnet/POS/Positioning-RC.jpg',
        },
        embroideryurl: {
          key: 2,
          value: '348651/3.m/159743.dst',
          default: '348651/3.m/159743.dst',
        },
        embroiderycolors: {
          key: 3,
          value: ['m1623','m1777','m1900','m1039','m1270','m1980','m1635'],
          default: ['m1039','m1980'],
        },
        text1: {
          key: 4,
          value: 'Acushnet',
          default: '',
        },
        text2: {
          key: 5,
          value: '',
          default: '',
        },
        text3: {
          key: 6,
          value: '',
          default: '',
        },
        text4: {
          key: 7,
          value: '',
          default: '',
        },
        truetypefont: {
          key: 8,
          value: ['BriquetSub','caslonop','KabelItcTEEDem','MANGAL','VLADIMIR'],
          default: 'MANGAL',
        },
        wilcomfont: {
          key: 8,
          value: ['Athletica','Energy','Jikharev','Market','Russian Textbook'],
          default: 'Athletica',
        },
        fontcolor: {
          key: 9,
          value: ['m1623','m1777','m1900','m1039','m1270','m1980','m1635'],
          default: 'm1039',
        },
        imprinttype: {
          key: 12,
          value: ['Imprint type 1'],
          default: 'Imprint type 1',
        }
      },
      relations: {
        BASIC: ['location','type'],
        TXT: ['location','type','text1','text2','text3','text4','truetypefont','fontcolor'],
        LTR: ['location','type','text1','wilcomfont','fontcolor'],
        IMG: ['location','type','imageurl1'],
        EMB: ['location','type','embroideryurl','embroiderycolors']
      }
    },
    {
      title: 'Polo',
      name: 'shirt_US_F20_08',
      materialname: 'shirt_US_F20_08',
      materialoption: 'shirt_US_F20_08',
      channel: 'diffuseTexture',
      prodID: '26374C',
      keygroups: [3,5,7,9,11,13,15,17,19,21,23,25],
      options: {
        productid: {
          key: 'prodID',
          value: ['26374C','26375C','26376C'],
          default: '26374C',
        },
        location: {
          key: 0,
          value: [
            { value: 'CCB', text: 'Collar Center Back'},
            { value: 'LBS', text: 'Left Back Shoulder'},
            { value: 'LC', text: 'Left Chest'},
            { value: 'LCO', text: 'Left Collar'},
            { value: 'LFH', text: 'Left Front Hem'},
            { value: 'LS', text: 'Left Sleeve'},
            { value: 'RBS', text: 'Right Back Shoulder'},
            { value: 'RC', text: 'Right Chest'},
            { value: 'RCO', text: 'Right Collar'},
            { value: 'RFH', text: 'Right Front Hem'},
            { value: 'RS', text: 'Right Sleeve'},
            { value: 'YBS', text: 'Yoke'}
          ],
          default: 'RC',
        },
        type: {
          key: 1,
          value: [
            { value: 'BASIC', text: 'No personalization'},
            { value: 'IMG', text: 'Image upload'},
            { value: 'TXT', text: 'Text sublimation'},
            { value: 'LTR', text: 'Embroidery lettering'},
            { value: 'EMB', text: 'Artworks embroidery'}
          ],
          default: 'BASIC',
        },
        imageurl1: {
          key: 2,
          value: 'https://pixnetiq.com/acushnet/POS/Positioning-RS.jpg',
          default: 'https://pixnetiq.com/acushnet/POS/Positioning-RC.jpg',
        },
        embroideryurl: {
          key: 2,
          value: '348651/3.m/159743.dst',
          default: '348651/3.m/159743.dst',
        },
        embroiderycolors: {
          key: 3,
          value: ['m1623','m1777','m1900','m1039','m1270','m1980','m1635'],
          default: ['m1039','m1980'],
        },
        text1: {
          key: 4,
          value: 'Acushnet',
          default: '',
        },
        text2: {
          key: 5,
          value: '',
          default: '',
        },
        text3: {
          key: 6,
          value: '',
          default: '',
        },
        text4: {
          key: 7,
          value: '',
          default: '',
        },
        truetypefont: {
          key: 8,
          value: ['BriquetSub','caslonop','KabelItcTEEDem','MANGAL','VLADIMIR'],
          default: 'MANGAL',
        },
        wilcomfont: {
          key: 8,
          value: ['Athletica','Energy','Jikharev','Market','Russian Textbook'],
          default: 'Athletica',
        },
        fontcolor: {
          key: 9,
          value: ['m1623','m1777','m1900','m1039','m1270','m1980','m1635'],
          default: 'm1039',
        },
        imprinttype: {
          key: 12,
          value: ['Imprint type 1'],
          default: 'Imprint type 1',
        }
      },
      relations: {
        BASIC: ['location','type'],
        TXT: ['location','type','text1','text2','text3','text4','truetypefont','fontcolor'],
        LTR: ['location','type','text1','wilcomfont','fontcolor'],
        IMG: ['location','type','imageurl1'],
        EMB: ['location','type','embroideryurl','embroiderycolors']
      }
    },
    {
      title: 'Polo',
      name: 'shirt_US_F20_10',
      materialname: 'shirt_US_F20_10',
      materialoption: 'shirt_US_F20_10',
      channel: 'diffuseTexture',
      prodID: '26380C',
      keygroups: [3,5,7,9,11,13,15,17,19,21,23,25],
      options: {
        productid: {
          key: 'prodID',
          value: ['26380C','26381C'],
          default: '26380C',
        },
        location: {
          key: 0,
          value: [
            { value: 'CCB', text: 'Collar Center Back'},
            { value: 'LBS', text: 'Left Back Shoulder'},
            { value: 'LC', text: 'Left Chest'},
            { value: 'LCO', text: 'Left Collar'},
            { value: 'LFH', text: 'Left Front Hem'},
            { value: 'LS', text: 'Left Sleeve'},
            { value: 'RBS', text: 'Right Back Shoulder'},
            { value: 'RC', text: 'Right Chest'},
            { value: 'RCO', text: 'Right Collar'},
            { value: 'RFH', text: 'Right Front Hem'},
            { value: 'RS', text: 'Right Sleeve'},
            { value: 'YBS', text: 'Yoke'}
          ],
          default: 'RC',
        },
        type: {
          key: 1,
          value: [
            { value: 'BASIC', text: 'No personalization'},
            { value: 'IMG', text: 'Image upload'},
            { value: 'TXT', text: 'Text sublimation'},
            { value: 'LTR', text: 'Embroidery lettering'},
            { value: 'EMB', text: 'Artworks embroidery'}
          ],
          default: 'BASIC',
        },
        imageurl1: {
          key: 2,
          value: 'https://pixnetiq.com/acushnet/POS/Positioning-RS.jpg',
          default: 'https://pixnetiq.com/acushnet/POS/Positioning-RC.jpg',
        },
        embroideryurl: {
          key: 2,
          value: '348651/3.m/159743.dst',
          default: '348651/3.m/159743.dst',
        },
        embroiderycolors: {
          key: 3,
          value: ['m1623','m1777','m1900','m1039','m1270','m1980','m1635'],
          default: ['m1039','m1980'],
        },
        text1: {
          key: 4,
          value: 'Acushnet',
          default: '',
        },
        text2: {
          key: 5,
          value: '',
          default: '',
        },
        text3: {
          key: 6,
          value: '',
          default: '',
        },
        text4: {
          key: 7,
          value: '',
          default: '',
        },
        truetypefont: {
          key: 8,
          value: ['BriquetSub','caslonop','KabelItcTEEDem','MANGAL','VLADIMIR'],
          default: 'MANGAL',
        },
        wilcomfont: {
          key: 8,
          value: ['Athletica','Energy','Jikharev','Market','Russian Textbook'],
          default: '',
        },
        fontcolor: {
          key: 9,
          value: ['m1623','m1777','m1900','m1039','m1270','m1980','m1635'],
          default: 'm1039',
        },
        imprinttype: {
          key: 12,
          value: ['Imprint type 1'],
          default: 'Imprint type 1',
        }
      },
      relations: {
        BASIC: ['location','type'],
        TXT: ['location','type','text1','text2','text3','text4','truetypefont','fontcolor'],
        LTR: ['location','type','text1','wilcomfont','fontcolor'],
        IMG: ['location','type','imageurl1'],
        EMB: ['location','type','embroideryurl','embroiderycolors']
      }
    },
    {
      title: 'Polo',
      name: 'shirt_US_F20_13',
      materialname: 'shirt_US_F20_13',
      materialoption: 'shirt_US_F20_13',
      channel: 'diffuseTexture',
      prodID: '26387C',
      keygroups: [3,5,7,9,11,13,15,17,19,21,23,25],
      options: {
        productid: {
          key: 'prodID',
          value: ['26387C','26388C'],
          default: '26387C',
        },
        location: {
          key: 0,
          value: [
            { value: 'CCB', text: 'Collar Center Back'},
            { value: 'LBS', text: 'Left Back Shoulder'},
            { value: 'LC', text: 'Left Chest'},
            { value: 'LCO', text: 'Left Collar'},
            { value: 'LFH', text: 'Left Front Hem'},
            { value: 'LS', text: 'Left Sleeve'},
            { value: 'RBS', text: 'Right Back Shoulder'},
            { value: 'RC', text: 'Right Chest'},
            { value: 'RCO', text: 'Right Collar'},
            { value: 'RFH', text: 'Right Front Hem'},
            { value: 'RS', text: 'Right Sleeve'},
            { value: 'YBS', text: 'Yoke'}
          ],
          default: 'RC',
        },
        type: {
          key: 1,
          value: [
            { value: 'BASIC', text: 'No personalization'},
            { value: 'IMG', text: 'Image upload'},
            { value: 'TXT', text: 'Text sublimation'},
            { value: 'LTR', text: 'Embroidery lettering'},
            { value: 'EMB', text: 'Artworks embroidery'}
          ],
          default: 'BASIC',
        },
        imageurl1: {
          key: 2,
          value: 'https://pixnetiq.com/acushnet/POS/Positioning-RS.jpg',
          default: 'https://pixnetiq.com/acushnet/POS/Positioning-RC.jpg',
        },
        embroideryurl: {
          key: 2,
          value: '348651/3.m/159743.dst',
          default: '348651/3.m/159743.dst',
        },
        embroiderycolors: {
          key: 3,
          value: ['m1623','m1777','m1900','m1039','m1270','m1980','m1635'],
          default: ['m1039','m1980'],
        },
        text1: {
          key: 4,
          value: 'Acushnet',
          default: '',
        },
        text2: {
          key: 5,
          value: '',
          default: '',
        },
        text3: {
          key: 6,
          value: '',
          default: '',
        },
        text4: {
          key: 7,
          value: '',
          default: '',
        },
        truetypefont: {
          key: 8,
          value: ['BriquetSub','caslonop','KabelItcTEEDem','MANGAL','VLADIMIR'],
          default: 'MANGAL',
        },
        wilcomfont: {
          key: 8,
          value: ['Athletica','Energy','Jikharev','Market','Russian Textbook'],
          default: '',
        },
        fontcolor: {
          key: 9,
          value: ['m1623','m1777','m1900','m1039','m1270','m1980','m1635'],
          default: 'm1039',
        },
        imprinttype: {
          key: 12,
          value: ['Imprint type 1'],
          default: 'Imprint type 1',
        }
      },
      relations: {
        BASIC: ['location','type'],
        TXT: ['location','type','text1','text2','text3','text4','truetypefont','fontcolor'],
        LTR: ['location','type','text1','wilcomfont','fontcolor'],
        IMG: ['location','type','imageurl1'],
        EMB: ['location','type','embroideryurl','embroiderycolors']
      }
    },
    {
      title: 'Jacket',
      name: 'jacket_US_OW_156',
      materialname: 'jacket_US_OW_156',
      materialoption: 'jacket_US_OW_156',
      channel: 'diffuseTexture',
      prodID: '25160C',
      keygroups: [],
      options: {
        productid: {
          key: 'prodID',
          value: ['25160C', '25161C', '25162C'],
          default: '25160C',
        },
        location: {
          key: 0,
          value: [
            { value: 'CCB', text: 'Collar Center Back'},
            { value: 'LBS', text: 'Left Back Shoulder'},
            { value: 'LC', text: 'Left Chest'},
            { value: 'LCO', text: 'Left Collar'},
            { value: 'LFH', text: 'Left Front Hem'},
            { value: 'LS', text: 'Left Sleeve'},
            { value: 'RBS', text: 'Right Back Shoulder'},
            { value: 'RC', text: 'Right Chest'},
            { value: 'RCO', text: 'Right Collar'},
            { value: 'RFH', text: 'Right Front Hem'},
            { value: 'RS', text: 'Right Sleeve'},
            { value: 'YBS', text: 'Yoke'}
          ],
          default: 'RC',
        },
        type: {
          value: [
            { value: 'BASIC', text: 'No personalization'},
            { value: 'IMG', text: 'Image upload'},
            { value: 'TXT', text: 'Text sublimation'},
            { value: 'LTR', text: 'Embroidery lettering'},
            { value: 'EMB', text: 'Artworks embroidery'}
          ],
        },
        imageurl1: {
          key: 2,
          value: 'https://pixnetiq.com/acushnet/POS/Positioning-RS.jpg',
          default: 'https://pixnetiq.com/acushnet/POS/Positioning-RC.jpg',
        },
        embroideryurl: {
          key: 2,
          value: '348651/3.m/159743.dst',
          default: '348651/3.m/159743.dst',
        },
        embroiderycolors: {
          key: 3,
          value: ['m1623','m1777','m1900','m1039','m1270','m1980','m1635'],
          default: ['m1039','m1980'],
        },
        text1: {
          key: 4,
          value: 'Acushnet',
          default: '',
        },
        text2: {
          key: 5,
          value: '',
          default: '',
        },
        text3: {
          key: 6,
          value: '',
          default: '',
        },
        text4: {
          key: 7,
          value: '',
          default: '',
        },
        truetypefont: {
          key: 8,
          value: ['BriquetSub','caslonop','KabelItcTEEDem','MANGAL','VLADIMIR'],
          default: 'MANGAL',
        },
        wilcomfont: {
          key: 8,
          value: ['Athletica','Energy','Jikharev','Market','Russian Textbook'],
          default: '',
        },
        fontcolor: {
          key: 9,
          value: ['m1623','m1777','m1900','m1039','m1270','m1980','m1635'],
          default: 'm1039',
        },
        imprinttype: {
          key: 12,
          value: ['Imprint type 1'],
          default: 'Imprint type 1',
        }
      },
      relations: {
        // BASIC: ['location','type'],
        // TXT: ['location','type','text1','text2','text3','text4','truetypefont','fontcolor'],
        // LTR: ['location','type','text1','wilcomfont','fontcolor'],
        // IMG: ['location','type','imageurl1'],
        // EMB: ['location','type','embroideryurl','embroiderycolors']
      }
    },
    {
      title: 'Jacket',
      name: 'jacket_US_OW_157',
      materialname: 'jacket_US_OW_157',
      materialoption: 'jacket_US_OW_157',
      channel: 'diffuseTexture',
      prodID: '35376C',
      keygroups: [],
      options: {
        productid: {
          key: 'prodID',
          value: ['35376C', '35377C', '35378C', '35379C', '35380C'],
          default: '35376C',
        },
        location: {
          key: 0,
          value: [
            { value: 'CCB', text: 'Collar Center Back'},
            { value: 'LBS', text: 'Left Back Shoulder'},
            { value: 'LC', text: 'Left Chest'},
            { value: 'LCO', text: 'Left Collar'},
            { value: 'LFH', text: 'Left Front Hem'},
            { value: 'LS', text: 'Left Sleeve'},
            { value: 'RBS', text: 'Right Back Shoulder'},
            { value: 'RC', text: 'Right Chest'},
            { value: 'RCO', text: 'Right Collar'},
            { value: 'RFH', text: 'Right Front Hem'},
            { value: 'RS', text: 'Right Sleeve'},
            { value: 'YBS', text: 'Yoke'}
          ],
          default: 'RC',
        },
        type: {
          value: [
            { value: 'BASIC', text: 'No personalization'},
            { value: 'IMG', text: 'Image upload'},
            { value: 'TXT', text: 'Text sublimation'},
            { value: 'LTR', text: 'Embroidery lettering'},
            { value: 'EMB', text: 'Artworks embroidery'}
          ],
        },
        imageurl1: {
          key: 2,
          value: 'https://pixnetiq.com/acushnet/POS/Positioning-RS.jpg',
          default: 'https://pixnetiq.com/acushnet/POS/Positioning-RC.jpg',
        },
        embroideryurl: {
          key: 2,
          value: '348651/3.m/159743.dst',
          default: '348651/3.m/159743.dst',
        },
        embroiderycolors: {
          key: 3,
          value: ['m1623','m1777','m1900','m1039','m1270','m1980','m1635'],
          default: ['m1039','m1980'],
        },
        text1: {
          key: 4,
          value: 'Acushnet',
          default: '',
        },
        text2: {
          key: 5,
          value: '',
          default: '',
        },
        text3: {
          key: 6,
          value: '',
          default: '',
        },
        text4: {
          key: 7,
          value: '',
          default: '',
        },
        truetypefont: {
          key: 8,
          value: ['BriquetSub','caslonop','KabelItcTEEDem','MANGAL','VLADIMIR'],
          default: 'MANGAL',
        },
        wilcomfont: {
          key: 8,
          value: ['Athletica','Energy','Jikharev','Market','Russian Textbook'],
          default: '',
        },
        fontcolor: {
          key: 9,
          value: ['m1623','m1777','m1900','m1039','m1270','m1980','m1635'],
          default: 'm1039',
        },
        imprinttype: {
          key: 12,
          value: ['Imprint type 1'],
          default: 'Imprint type 1',
        }
      },
      relations: {
        BASIC: ['location','type'],
        TXT: ['location','type','text1','text2','text3','text4','truetypefont','fontcolor'],
        LTR: ['location','type','text1','wilcomfont','fontcolor'],
        IMG: ['location','type','imageurl1'],
        EMB: ['location','type','embroideryurl','embroiderycolors']
      }
    }
  ],
  liquifire: {
    sink: {
      format: "jpg",
      quality: 90,
      nocache: false
    },
    baseurl: 'https://pixnetiqma.liquifire.com/pixnetiqma',
    chain: 'file:FootJoy/webgl-main.chain',
    headers: 'addheader=key[Access-Control-Allow-Origin],value[*]',
    keys: [
      {
        name: "objid",
        description: "object ID, 1 = pocket",
        value: 0
      },
      {
        name: "size",
        description: "Texture size: medium = 1k, large = 2k",
        value: 2048
      },
      {
        name: "cors",
        description: "CORS headers, not un use",
        value: "foo"
      },
      {
        name: "type",
        description: "not in use, make sure sink format = png",
        options: ["diffuse", "reflection", "glossiness", "normal", "refraction", "bump", "ior", "rgba"],
        value: "foo"
      },
      {
        name: "render",
        description: "Render Engine",
        options: ["webgl", "im"],
        value: "webgl"
      },
      {
        name: "angle",
        description: "Angle (Depends on number of angles available for ImageMap)",
        options: [1, 2, 3],
        value: 3
      },
      {
        name: "view",
        description: "View (Reserved for later use)",
        options: [1, 2, 3],
        value: 3
      },
      {
        name: "awhost",
        description: "artworks server to use (1=test, 2=production)",
        value: 1
      }
    ],
    options: [
    ]
  },
  visibilitysingle: [
    {
      title: 'Shirt',
      visible: true,
      name: 'shirt',
      objects: [
        'body',
        'button_stitching',
        'buttons',
        'collar_stand',
        'labels',
        'placket',
        'sleeves',
        'stitches'
      ]
    },
    {
      title: 'Buttons',
      visible: true,
      name: 'buttons',
      objects: [
        'button_stitching',
        'buttons'
      ]
    }
  ],
  visibilitygroup: [
  ],
  materials: [
    {
      name: 'shoe',
      library: 'empty',
      payload: {
        type: 'PBRSpecularGlossinessMaterial',
        diffuseColor: [1.0, 1.0, 1.0],
        glossiness: 1.0,
        specularColor: [0.0, 0.0, 0.0]
      }
    }
  ],
  views: [
    {
      title: 'Start',
      name: 'start',
      payload: {
        alpha: -90,
        beta: 90,
        radius: 1.3,
        lowerRadiusLimit: 0.5,
        upperRadiusLimit: 1.8,
        upperBetaLimit: 140,
        wheelPrecision: 0.1,
        wheelDeltaPercentage: 0.02,
        maxZ: 60,
        minZ: 0.12,
        target: [0, 1.3, 0],
        fov: 45
      }
    },
    {
      title: 'Front',
      name: 'front',
      payload: {
        alpha: -1.58,
        beta: 1.49,
        radius: 0.5,
        fov: 45
      }
    },
    {
      title: 'Side',
      name: 'side',
      payload: {
        alpha: -3.14,
        beta: 1.49,
        radius: 0.5,
        fov: 45
      }
    },
    {
      title: 'Sink',
      name: 'sink',
      payload: {
        alpha: -1.58,
        beta: 0.5,
        radius: 0.5,
        fov: 45
      }
    }
  ],
  player: [
    {
      name: 'avoidunder',
      library: 'avoidunder',
      payload: {
        orbit_constraint_pitch_down: -1.5,
        orbit_constraint_zoom_in: 5,
        orbit_constraint_zoom_out: 25,
        transparent: 1
      }
    }
  ],
  background: [
    {
      name: 'white',
      library: 'white',
      payload: {
      }
    }
  ],
  environment: [
    {
      name: 'softgeneric',
      library: 'empty',
      payload: {
        environmentmap: 'environment/Assembled_environment_004.hdr',
        exposure: 0.8,
        contrast: 1.0,
        clearcolor: [1, 1, 1]
      }
    },
    {
      name: 'softvignette',
      library: 'empty',
      payload: {
        environmentmap: 'environment/Assembled_environment_004.hdr',
        exposure: 0.9,
        contrast: 1.0,
        clearcolor: [1, 1, 1],
        vignetteEnabled: true,
        vignetteWeight: 4,
        vignetteCameraFov: 0.4
      }
    }
  ],
  postprocessing: [
    {
      name: 'ssao',
      library: 'empty',
      payload: {
        ssaoRatio: 1.0,
        blurRatio: 1.0,
        radius: 0.3,
        samples: 32,
        maxZ: 10,
        minZAspect: 500
      }
    }
  ],
  lighting: [
    {
      name: '2point',
      library: 'empty',
      payload: {
        lights: [
          {
            name: 'light1',
            vector: [-1, -1.5, 1.2],
            pos: [-6, 6, -6],
            intensity: 1.0,
            shadowMinZ: 3,
            shadowMaxZ: 50
          },
          {
            name: 'light3',
            vector: [1, -1, -1],
            pos: [6, 6, 6],
            intensity: 0.8
          },
          {
            name: 'light2',
            vector: [0, -1, 0],
            pos: [0, 25, 0],
            intensity: 0.0,
            includedOnlyMeshes: ['ground']
          }
        ],
        shadowgenerator: {
          lightname: 'light1',
          size: 2048,
          bias: 0.001
        }
      }
    },
    {
      name: 'footjoy_2point',
      library: 'empty',
      payload: {
        lights: [
          {
            name: 'light1',
            vector: [0,0,0.5],
            pos: [-6, 6, -6],
            intensity: 1.0,
            shadowMinZ: 3,
            shadowMaxZ: 50
          },
          {
            name: 'light3',
            vector: [0.5,0.0,-0.9],
            pos: [6, 6, 6],
            intensity: 1.7
          },
        ],
        shadowgenerator: {
          // lightname: 'light1',
          // size: 2048,
          // bias: 0.001
        }
      }
    }
  ],
  annotations: [
  ],
  textoptions: [
    {
      name: 'groundtext',
      title: 'Type on ground',
      scenematerial: 'groundtext',
      text: 'Klaas is awesome',
      payload: {
        size: {width: 512, height: 512},
        font: '32px arial',
        canvasColor: 'rgba(255, 255, 255, 1.0)',
        textColor: 'rgba(120, 120, 120, 1)',
        channels: {
          albedoTexture: null
        }
      }
    }
  ],
  labels: [

  ],
  keygroups: [
    {
      basenumber: 3,
      sequential: 1,
      liquid: {}
    },
    {
      basenumber: 5,
      sequential: 2,
      liquid: {}
    },
    {
      basenumber: 7,
      sequential: 3,
      liquid: {}
    },
    {
      basenumber: 9,
      sequential: 4,
      liquid: {}
    },
    {
      basenumber: 11,
      sequential: 5,
      liquid: {}
    },
    {
      basenumber: 13,
      sequential: 6,
      liquid: {}
    },
    {
      basenumber: 15,
      sequential: 7,
      liquid: {}
    },
    {
      basenumber: 17,
      sequential: 8,
      liquid: {}
    },
    {
      basenumber: 19,
      sequential: 9,
      liquid: {}
    },
    {
      basenumber: 21,
      sequential: 10,
      liquid: {}
    },
    {
      basenumber: 23,
      sequential: 11,
      liquid: {}
    },
    {
      basenumber: 25,
      sequential: 12,
      liquid: {}
    },
  ]
}

export default template
