import _base from './_base-module'
import MenuItem from '@/classes/MenuItem'
import templateData from 'ProjectData/template'
import libraryData from 'ProjectData/library'

const Model = MenuItem

const state = {
  all: []
}

const getters = {
  ..._base.getters
}

function uuidv4() {
  // let cryptoObj = window.crypto || window.msCrypto;
  // return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
  //   (c ^ cryptoObj.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  // );
  return Math.floor(Math.random() * 64000)
}

//the optionslist can come from a prefab array. This is practical when a lot of
//parts use the same options all over the product
function assembleOptionslist(currentOptions, basedonName) {
  if (currentOptions === undefined) {currentOptions = []}
  if (basedonName) {
    let basedon = templateData.materialoptionslists.find(item => item.name === basedonName)
    if (basedon) {
      basedon.options.forEach(element => {
        currentOptions.push({...element})
      });
    }
  }
  return currentOptions
}

function mergePayload(payload1, payload2) {
  let payload = {}
  for (const channel in payload1) {
    if (payload1[channel] && payload2 && payload2[channel]) {
      let mergedChannel
      if (typeof payload2[channel] == 'number') {
        mergedChannel = payload2[channel]
      } else if (typeof payload2[channel] == 'string') {
        mergedChannel = payload2[channel]
      } else {
        mergedChannel = {...payload1[channel], ...payload2[channel]}
      }
      payload[channel] = mergedChannel
    } else {
      payload[channel] = payload1[channel]
    }
  }
  for (const channel in payload2) {
    if (payload[channel] === undefined) {
      payload[channel] = payload2[channel]
    }
  }
  return payload
}

function assemblePayload(currentPayload, basedonName) {
  let payload = {}
  if (basedonName) {
    let basedon = templateData.materials.find(item => item.name === basedonName)
    if (basedon) {
      payload = mergePayload(basedon.payload, currentPayload)
      const lib = libraryData.materials.find(item => item.name === basedon.library)
      if (lib) {
        payload = mergePayload(lib.payload, payload) //{...lib.payload, ...payload, ...currentPayload}
      }
    }
  }
  return payload
}

function getBasedonSwatch(basedonName) {
  if (basedonName) {
    let basedon = templateData.materials.find(item => item.name === basedonName)
    if (basedon) {
      return basedon.swatch
    }
  }
  return null
}

function buildMaterialFromMenuoption(option) {
  // Found a material! Let's find it and assemble
  // it from the template and library
  let mtl = templateData.materialoptions.find(item => item.name === option.material)
  if (mtl === undefined) {
    console.log('Couldn\'t find material in the template:', option.material)
  }
  if (mtl.type === 'single' || mtl.type === 'overrideexclude') {
    mtl.options = assembleOptionslist(mtl.options, mtl.optionslistbasedon)
    mtl.optionslistbasedon = ''
    // mtl.options = mtl.options.concat(options)
    // console.log('mtl.name', mtl.name)
    for (let mtloption of mtl.options) {
      mtloption.id = uuidv4()
      const payload = assemblePayload(mtloption.payload, mtloption.basedon)
      mtloption.payload = mergePayload(payload, mtloption.payload)
      const basedonSwatch = getBasedonSwatch(mtloption.basedon)
      mtloption.swatch = mtloption.swatch ? mtloption.swatch : basedonSwatch
    }
  }
  if (mtl.type === 'colorway') {
    for (let mtloption of mtl.options) {
      for (let mtlpayload of mtloption.payload) {
        mtloption.id = uuidv4()
        const payload = assemblePayload(mtlpayload.payload, mtlpayload.basedon)
        mtlpayload.payload = mergePayload(payload, mtlpayload.payload)
        const basedonSwatch = getBasedonSwatch(mtlpayload.basedon)
        mtloption.swatch = mtloption.swatch ? mtloption.swatch : basedonSwatch
      }
    }
  }
  if (mtl.type === 'double') {
    mtl.options = assembleOptionslist(mtl.options, mtl.optionslistbasedon)
    mtl.optionslist1basedon = ''
    mtl.options2 = assembleOptionslist(mtl.options2, mtl.optionslist2basedon)
    mtl.optionslist2basedon = ''

    for (let mtloption of mtl.options) {
      mtloption.id = uuidv4()
      const payload = assemblePayload(mtloption.payload, mtloption.basedon)
      mtloption.payload = mergePayload(payload, mtloption.payload)
      const basedonSwatch = getBasedonSwatch(mtloption.basedon)
      mtloption.swatch = mtloption.swatch ? mtloption.swatch : basedonSwatch
    }
    for (let mtloption of mtl.options2) {
      mtloption.id = uuidv4()
      const payload = assemblePayload(mtloption.payload, mtloption.basedon)
      mtloption.payload = mergePayload(payload, mtloption.payload)
      const basedonSwatch = getBasedonSwatch(mtloption.basedon)
      mtloption.swatch = mtloption.swatch ? mtloption.swatch : basedonSwatch
    }
  }

  if (mtl.visible === undefined) {
    mtl.visible = mtl.options[0].name
  }
  return mtl
}

const actions = {
  setCurrentMaterial ({ commit }, {material, option}) {
    commit('updateSelectedMaterial', {material: material, option: option})
  },
  fetchData ({ commit }, {menu}) {
    commit('clearMaterials')
    // Search the menu for materials
    for (let menuitem of menu) {
      if (menuitem.options) {
        for (let option of menuitem.options) {
          if (option.material) {
            const mtl = buildMaterialFromMenuoption(option)
            let currentoption = mtl.options.find(item => item.name === mtl.visible)
            commit('addMaterial', mtl)
            commit('updateSelectedMaterial', {material: mtl, option: currentoption})     
          }
        }
      }
      if (menuitem.submenu) {
        for (let submenuitem of menuitem.submenu) {
          if (submenuitem.options) {
            for (let option of submenuitem.options) {
              if (option.material) {
                const mtl = buildMaterialFromMenuoption(option)
                let currentoption = mtl.options.find(item => item.name === mtl.visible)
                commit('addMaterial', mtl)
                commit('updateSelectedMaterial', {material: mtl, option: currentoption})
              }  
            }
          }
        }
      }
    }
  }
}

const mutations = {
  init(state, set) {
    return _base.mutations.init(state, set, Model);
  },
  addMaterial (state, material) {
    state.all.push(material)
  },
  clearMaterials (state) {
    state.all = []
  },
  updateSelectedMaterial (state, payload) {
    state.all = state.all.map(item => {
      if (item.name === payload.material.name) {
        item.currentTitle = payload.option.title
        item.swatch = payload.option.swatch
        item.icon = payload.option.icon
        item.visible = payload.option.name
      } return item
    })
  }, 
  updateMaterialoptionLiquid (state, payload) {
    state.all = state.all.map(material => {
      if (material.name === payload.materialname) {
        material.options = material.options.map(materialoption => {
          if (materialoption.name === payload.optionname) {
            materialoption.payload[payload.channel].liquid = payload.liquid
            return materialoption
          }
        })
      } return material
    })
  }, 
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
