import sceneTools from './scene'
import {DefaultRenderingPipeline} from '@babylonjs/core';
import {SSAO2RenderingPipeline} from '@babylonjs/core';


const setupPostProcess = function (settings){
    // // let sc = scene.getScene()



    if (/*@cc_on!@*/false || !!document.documentMode) //IF IE 6-11
    {
      console.log('No WebGL2 in IE11')
    } else {
    
        // Create SSAO and configure all properties (for the example)
        var ssaoRatio = {
            ssaoRatio: settings.ssaoRatio, // Ratio of the SSAO post-process, in a lower resolution
            blurRatio: settings.blurRatio// Ratio of the combine post-process (combines the SSAO and the scene)
        };

        var ssao = new SSAO2RenderingPipeline("ssao2", sceneTools.scene, ssaoRatio);
        if (ssao.isSupported) {
            ssao.radius = settings.radius;
            ssao.base = 0.1;
            ssao.totalStrength = 2;
            ssao.expensiveBlur = true;
            ssao.samples = settings.samples;
            //these are important to avoid z-fighting. Tweak them according to the size of the scene
            ssao.maxZ = settings.maxZ;
            ssao.minZAspect = settings.minZAspect;
            

            // Attach camera to the SSAO render pipeline
            sceneTools.scene.postProcessRenderPipelineManager.attachCamerasToRenderPipeline("ssao2", sceneTools.scene._activeCamera);
        }
    }

    var defaultPipeline = new DefaultRenderingPipeline(
        "pipeline", // The name of the pipeline
        true, // Do you want HDR textures ?
        sceneTools.scene, // The scene instance
        [sceneTools.scene._activeCamera] // The list of cameras to be attached to
    );
    if (defaultPipeline.isSupported) {
        defaultPipeline.fxaaEnabled = true; //enabling this, disables the ssao2
        defaultPipeline.samples = 8;
    }

}

export default { setupPostProcess }
