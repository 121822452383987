import _base from './_base-module'
import templateData from 'ProjectData/template'

const getOption = function (option) {
  if (option.visibilitysingle) {
    return templateData.visibilitysingle.find(item => item.name === option.visibilitysingle)
  }
  return null
}

const state = {
  all: []
}

const getters = {
  ..._base.getters
}

const actions = {
  fetchData ({ commit }, {menu}) {
    commit('clear')
    let theOptions = []
    for (let menuitem of menu) {
      if (menuitem.options) {
        for (let option of menuitem.options) {
          const theOption = getOption(option)          
          theOption ? theOptions.push(theOption) : null
        }
      }
      if (menuitem.submenu) {
        for (let submenuitem of menuitem.submenu)
        {
          if (submenuitem.options) {
            for (let option of submenuitem.options) {
              const theOption = getOption(option)
              theOption ? theOptions.push(theOption) : null
            }
          }
        }
      }
    }
    for (let o of theOptions) {
      if (o.exactname === undefined) {o.exactname = true}
      if (o.visible === undefined) {o.visible = true}
      if (o.type === undefined) {o.type = 'single'}
      commit('addOption', o)
    }
  }
}

const mutations = {
  ..._base.mutations,
  addOption (state, theOption) {
    state.all.push(theOption)
  },
  clear (state) {
    state.all = []
  },  
  setVisibility (state, payload) {
    state.all = state.all.map(item => { if (item.name === payload.optionName) { item.visible = payload.switch } return item })
  }  
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
