import store from 'Store'
import LiquiFire from '@/classes/liquifire'


const isOptionInRelation  = function (liquidTemplate, keygroupType, optionName) {
  //find out if a certain ui option belongs to a type of keygroup
  if (liquidTemplate && keygroupType) {
    return liquidTemplate.relations[keygroupType].includes(optionName)
  } else {
    return false
  }
}

const createKeygroupLiquidPayload = function (liquidTemplate, keygroup, uiData) {
  //set up the keys, only send the relevant keys to the current type
  let keys = {}
  if (keygroup) {
    const basekey = keygroup.basenumber * 10

    const options = ['location','type','imageurl1','imageurl2','imageurl3','embroideryurl','text1','text2','text3','text4','truetypefont','wilcomfont','fontcolor','imprinttype',]

    if (uiData.type !== 'BASIC') {
      options.forEach(element => {
        if (isOptionInRelation( liquidTemplate, uiData.type, element)) {
          keys['fv' + (basekey + liquidTemplate.options[element].key)] = uiData[element]
        }
      });
      if (isOptionInRelation( liquidTemplate, uiData.type, 'embroiderycolors')) {
        keys['fv' + (basekey + 3)] = uiData.embroiderycolors.join('~')
      }
    }
  }
  return keys
}

const updateMaterialFromKeygroups = function () {
  //go over all keygroups and assemble the full liquid setup
  const keygroups = store.state.keygroups.all
  let liquid = {}
  keygroups.forEach(keygroup => {
    if (keygroup.liquid) {
      const templiquid = {...liquid, ...keygroup.liquid}
      liquid = templiquid
    }
  });
  return liquid
}

const injectProductId = function (liquidname, liquiddata) {
  const liquid = store.getters['liquid/getItemByName'](liquidname)
  liquiddata.prodID = liquid.prodID
}


const getQueryParamsFromUrl = function (query) {
  if (query) {
    let queryObject = {}

    // split the query string into separate query elements
    const queryElements = query.split('&')

    // Each query element has a key and a value, separated by an =
    // If there are multiple of the same key, store each one of them
    queryElements.forEach(el => {
      const pair = el.split('=')
      if (!queryObject[pair[0]]) {
        queryObject[pair[0]] = [pair[1]]
      } else {
        queryObject[pair[0]].push(pair[1])
      }
    })
    console.log('Query parts', queryObject)
    return queryObject
  } else {
    return null
  }
}

const getLiquifireSetsFromQueryParams = function (params) {
  if (params?.set) {
    let sets = {}
    params.set.forEach(set => {
      //splits "key[fv31],value[IMG]" with regex. We just
      // want fv31 and IMG
      const result = set.split(/\[([^\]]*)],?/g)
      sets[result[1]] = result[3]
    })
    return sets
  } else {
    return null
  }
}

const getSearchComponent = function (url) {
  const parts = url.split(/\?(.*)/)
  if (parts[1]) {
    const cleanSearch = decodeURIComponent(parts[1]).replace(/&amp;/g, '&')
    console.log('Search', cleanSearch)
    return cleanSearch
  } else {
    return null
  }
}


const parseQuery = function () {
  const search = getSearchComponent(window.location.href)
  // console.log('search', search)
  const params = getQueryParamsFromUrl(search)
  // console.log('params', params)
  //parse query parameters. Currently only get the "set" key-value pairs
  //ignore everything else
  const sets = getLiquifireSetsFromQueryParams(params)

  if (sets) {
    console.log('Liquifire sets', sets)
    // let liquiFireState = {}
    // params.set.forEach(set => {
    //   //splits "key[fv31],value[IMG]" with regex. We just
    //   // want fv31 and IMG
    //   const result = set.split(/\[([^\]]*)],?/g)
    //   liquiFireState[result[1]] = result[3]
    // })
    const liquiFire = new LiquiFire(store.state.liquifire)

    Object.entries(sets).forEach(([key, value]) => { liquiFire.setOption(key, value) })
    return liquiFire
  } else {
    return null
  }
}

export default {
  isOptionInRelation, 
  createKeygroupLiquidPayload,
  updateMaterialFromKeygroups,
  injectProductId,
  parseQuery
}