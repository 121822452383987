class Keygroup {
  // active
  // basenumber
  // location
  // liquid
  // sequential

  constructor(data) {
    data.active ? this.active = data.active : this.active = false
    this.basenumber = data.basenumber
    this.location = data.location
    this.liquid = data.liquid
    this.sequential = data.sequential
  }
}

export default Keygroup