import _base from './_base-module'
import templateData from 'ProjectData/template'
import Keygroup from '@/classes/Keygroup'

const Model = Keygroup

const state = {
  all: []
}

const getters = {
  ..._base.getters,
  getAllActive (state) {
    return state.all.filter(item => item.active === true)
  },
  getAllowedFromArray: (state) => (keygroupIds) => {
    return state.all.filter(item => {
      return keygroupIds.includes(item.basenumber)
    })
  },
}


const actions = {
  fetchData ({ commit }) {
    commit('clearAll')
    templateData.keygroups && templateData.keygroups.forEach(item => {
      commit('addItem', item)
    })
  },
  activateNext ({ commit }, {keygroupIds}) {
    let allowedKeygroups = state.all.filter(item => keygroupIds.includes(item.basenumber))
    let firstInactive = allowedKeygroups.find(item => item.active === false)
    if (firstInactive) {
      commit('setActive', {basenumber: firstInactive.basenumber, active: true})
    }
  },
  deactivate ({ commit }, {basenumber}) {
    let keygroup = state.all.find(item => item.basenumber === basenumber)
    if (keygroup) {
      commit('setActive', {basenumber: keygroup.basenumber, active: false})
    }
  },
  updateLiquid ({ commit }, {basenumber, liquid}) {
    let keygroup = state.all.find(item => item.basenumber === basenumber)
    if (keygroup) {
      commit('setLiquid', {basenumber: keygroup.basenumber, liquid: liquid})
    }
  },
  setLocation ({ commit }, {basenumber, location}) {
    let keygroup = state.all.find(item => item.basenumber === basenumber)
    if (keygroup) {
      commit('setLocation', {basenumber: keygroup.basenumber, location: location})
    }
  },

}

const mutations = {
  addItem (state, item) {
    const keygroup = new Model(item)
    state.all.push(keygroup)
  },
  clearAll (state) {
    state.all = []
  },
  setActive (state, payload) {
    state.all = state.all.map(element => {
      if (element.basenumber === payload.basenumber) {
        element.active = payload.active
      }
      return element
    })
  },
  setLocation (state, payload) {
    state.all = state.all.map(element => {
      if (element.basenumber === payload.basenumber) {
        element.location = payload.location
      }
      return element
    })
  },
  setLiquid (state, payload) {
    state.all = state.all.map(element => {
      if (element.basenumber === payload.basenumber) {
        element.liquid = payload.liquid
      }
      return element
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
