<script>
import cardportfolio from 'Components/card-portfolio'

export default {
  name: 'cardCommercial',
  components: {
    cardportfolio
  },
  data: () => ({
    projects: [
      {
        title: 'Zodiac Nautic',
        subtitle: 'Semi inflatable boats',
        text: 'A bespoke configurator. Lots of color options and different products to choose from. Review and checkout your design and get a pdf with your design emailed to you automatically.',
        articleurl: 'https://www.klaasnienhuis.nl/2016/07/zodiac-nautic-boat-configurator/',
        url: 'https://configure.zodiac-nautic.com/',
        img: './img/zodiac.jpg'
      },
      {
        title: 'F.Domes',
        subtitle: 'Geodetic dome tents',
        text: 'A custom configurator with a responsive design. Speed up the sales process with live princing and shipping info.',
        articleurl: 'https://www.klaasnienhuis.nl/2018/06/f-domes-geodesic-dome-configurator/',
        url: 'https://3d.fdomes.com/',
        img: './img/fdomes.jpg'
      },
      {
        title: 'Gasgrill',
        subtitle: 'Professional gas grills',
        text: 'A responsive configurator with robust logic and rules. Create your new grill on your laptop or mobile and step into a live co-creation session with a sales agent.',
        articleurl: 'https://www.klaasnienhuis.nl',
        url: 'https://www.gasgrill-shop.com/3d-konfigurator/index.html#/',
        img: './img/gasgrill.jpg'
      }
    ]
  }),
  computed: {
    dialog: {
        get () {
            return this.$store.getters['ui/getItemByName']('projectsdialog')
        },
        set () {
            this.$store.commit('ui/updateItemByName', {key: 'projectsdialog', value: false})
        }
    },
      dialogColumns () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '12'
        case 'sm': return '12'
        case 'md': return '6'
        case 'lg': return '4'
        case 'xl': return '4'
        default: return '6'
      }
    },

    dialogWidth () {
      switch (this.$vuetify.breakpoint.name) {
        case 'sm': return 600
        case 'md': return 800
        case 'lg': return 1000
        case 'xl': return 1200
        default: return 800
      }
    }, 
  },

  methods: {
        closeDialog () {
            this.$store.commit('ui/updateItemByName', {key: 'projectsdialog', value: false})
        }
  },
  mounted () {
  }
}
</script>

<template>

    
    <v-dialog
      v-model="dialog"
      :width=dialogWidth
      :fullscreen="$vuetify.breakpoint.xsOnly"
      
    >
    <v-card>
      <v-container fluid>
        <v-row dense>
          <v-col
            v-for="project in projects"
            :key="project.title"
            :cols=dialogColumns
          >
            <cardportfolio :project="project">
            </cardportfolio>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          
          @click="closeDialog()"
        >
          OK Cool
        </v-btn>
      </v-card-actions>          
        </v-card>
    </v-dialog>

</template>

<style lang="scss" scoped>
.loader {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
