<script>
import liquidkeygroup from './liquid-keygroup'


import configure from '@/tools/configurator/configure'
import materialTools from '@/tools/babylon/materials'

import store from '@/store'

export default {
  name: 'liquidoption',
  components: {
    liquidkeygroup
  },
  props: {
    optionname: String
  },
  computed: {
    liquid () {
      return store.getters['liquid/getItemByName'](this.optionname)
    },
    activekeygroups () {
      return store.getters['keygroups/getAllActive']
    },
    allowedkeygroups () {
      return store.getters['keygroups/getAllowedFromArray'](this.liquid.keygroups)
    }
  },
  data: () => ({
    productid: ''
  }),
  mounted () {
    //set the default values
    Object.entries(this.liquid.options).forEach(([key,value]) => {
        this[key] = value.default
    })
  },
  methods: {
    changeOverflow () {
      //
      store.commit('liquid/setProductId', {liquidname: this.optionname, productid: this.productid})
      liquidkeygroup.methods.changeOverflow(this.optionname)
    },
    activateKeygroup () {
      store.dispatch('keygroups/activateNext', {keygroupIds: this.liquid.keygroups})
    }
  }
}
</script>

<template>
<v-card 
  color="card"
  flat
>
  <v-card-title>{{liquid.title}} {{productid}}</v-card-title>
  <v-select
    v-model="productid"
    class="mx-2 mb-2"
    :items="liquid.options.productid.value"
    label="Product ID"
    hint="Select the product ID"
    persistent-hint
    filled
    @change="changeOverflow()"
  ></v-select>

  <v-btn
    v-if="allowedkeygroups.length>0"
    raised
    class="ml-2"
    @click="activateKeygroup()"
  >
    Add a personalization
    <v-icon right dark>mdi-plus</v-icon>
  </v-btn>

  <v-expansion-panels
    accordion
    selectable
    tile
    hover
    color="card"
    class="mt-2 px-2"
  >
    <v-expansion-panel
      color="card"
      v-for="(item,i) in activekeygroups"
      :key="i"      
    >
      <v-expansion-panel-header color="cardaccent">Personalization {{ item.sequential }}</v-expansion-panel-header>
      <v-expansion-panel-content  color="card">
        <liquidkeygroup :optionname="optionname" :keygroup="item"/>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</v-card>

</template>

<style lang="scss" scoped>

</style>