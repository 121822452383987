<script>
import store from '../store'
import dialogcommercial from 'Components/dialog-commercial'

export default {
  name: 'fabcommercial',
  components: {
    dialogcommercial
  },
  props: {
  },
  data: () => ({
    fab: false
  }),
  computed: {
    toppadding () {
      if (this.$vuetify.breakpoint.name === 'xs') {
        return 'pt-4'

      }else{
        return 'pt-12'
      }
    },        
  },
  methods: {
    openDialog () {
      store.commit('ui/updateItemByName', {key: 'projectsdialog', value: true})
      // this.fab = false
    }
  }   
}
</script>

<template>
  <v-speed-dial
    v-model="fab"
    direction="top"
    transition="slide-y-transition"
    right
    class="speeddial d-flex align-end flex-column"
    bottom
    absolute
  >
    <dialogcommercial/>
    <template v-slot:activator>
      <v-btn
        color="primary"
        dark
        large
        rounded
      >
        <v-icon left large v-if="fab">mdi-close</v-icon>
        <v-icon left large v-else>mdi-emoticon</v-icon>
        There's more
      </v-btn>
    </template>

    <!-- <cardcommercial/> -->
    <v-card 
      color="card" 
      elevation=2
      class="cardcommercial"
    >
      <v-list-item >
        <v-list-item-avatar>
          <v-img src="../assets/img/Klaas_profilePicture.jpg"></v-img>      
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="headline">Klaas Nienhuis</v-list-item-title>
          <v-list-item-subtitle color="black">3D development</v-list-item-subtitle>
        </v-list-item-content> 
      </v-list-item>
      <v-card-text class="px-7 pt-0 textcontrast--text">
        Hi, I'm Klaas and I've built this demo. Get in touch to work with a team of specialists. We're experienced in building 3d configurators from A to Z.
      </v-card-text>
    </v-card>


    <v-btn
      dark
      color="primary"
      href="https://klaasnienhuis.freshsales.io/web_forms/404bcc64bc2b7d2fadc81f06d07264e35c21a9cc6c36e680a1bd52d8b929b501/form.html" 
      target="_blank"
    >
      Get in touch
      <v-icon right>mdi-email</v-icon>
    </v-btn>
    <v-btn
      dark
      color="primary"
      href=""
      @click.stop="openDialog()"
    >
      See more projects
      <v-icon right>mdi-information-outline</v-icon>
    </v-btn>
  </v-speed-dial>
</template>

<style lang="scss">
  .commercialspan {
    width: 220px;
  }
  .cardcommercial {
    width: min-content;
  }
  .speeddial {
    z-index: 5;
  }
  .v-speed-dial__list {
    align-items: flex-end;
    z-index: 2;
  }
  .v-speed-dial--direction-top {
    width: 250px;
    // left: -244px;
  }
</style>