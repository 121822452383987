<script>
import configure from '@/tools/configurator/configure'

export default {
  name: 'visibilitygroupmenu',
  components: {
  },
  props: {
    options: Array,
    name: String
  },
  data: () => ({
  }),
  computed: {
    visibilitygroup () {
      return this.$store.getters['visibilitygroup/getItemByName'](this.name)
    }  
  },
  methods: {
    setVisibilityOption (visibilitygroup, option) {
      this.$store.dispatch('visibilitygroup/updateSelectedGroup', {group: visibilitygroup, option: option})
      configure.SetVisibilityGroup(visibilitygroup, option, option.matchNameExactly)
    }
  }  
}
</script>

<template>
  <v-list
    color="white"
  >
    <v-list-item 
      v-for="(option, index) in options" 
      :key="index" 
      @click="setVisibilityOption(visibilitygroup, option)"
    >
      <v-list-item-title 
        v-text="option.title"
      ></v-list-item-title>
        <v-avatar 
          v-if="option.swatch" 
          :style="option.swatch" 
          tile
        >
        </v-avatar>      
    </v-list-item>
  </v-list>  
</template>