<script>
import scenetools from '@/tools/babylon/scene'
import geometryTools from '@/tools/babylon/geometry'
import cameraTools from '@/tools/babylon/camera'
import lightingTools from '@/tools/babylon/lighting'
import environmentTools from 'bjs_tool/environment'
import liquidTools from 'bjs_tool/liquid'

import materialTools from '@/tools/babylon/materials'
import configure from '@/tools/configurator/configure'
import init from '@/tools/configurator/init'
import splash from 'Components/splash'
import textureload from 'Components/textureload'
import store from 'Store'
import {Color4} from '@babylonjs/core';
import ResizeObserver from "resize-observer-polyfill";

import '@babylonjs/inspector'

// import '@babylonjs/loaders/glTF';

export default {
  name: 'playerBabylon',
  props: {
    sceneuid: String
  },
  components: {
    splash,
    textureload
  },
  data: function () {
    return {
      cameraMoves: false,
      resizeObserver: null
    }
  },  

  computed: {
    loaded () {
      return this.$store.getters['scenes/getsceneLoaded']
      // return true
    },
    ui () {
      return this.$store.getters['ui/getState']
    },    
    myStyle () {
      const pointer = this.ui.navigatebyannotationonly ? 'none' : 'all'

       return {
        'pointer-events': pointer
      }     
    }
  },
  mounted () {
    let myscene = scenetools.scene
    if (myscene) {
      console.log('DISPOSING SCENE')
      materialTools.clearScenematerials()
      myscene.dispose()
      scenetools.engine.dispose()
      scenetools.clear()
    }
    
    // Make sure the canvas is resized at all times
    this.resizeObserver = new ResizeObserver(this.onCanvasResize)
    this.resizeObserver.observe(this.$refs.canvas)

    // Try with this URL
    // http://localhost:8080/#/scene/US-F20-10?set=key%5BprodID%5D,value%5B26380C%5D&amp%3Bset=key%5Bfv70%5D,value%5BLC%5D&amp%3Bset=key%5Bfv71%5D,value%5BEMB%5D&amp%3Bset=key%5Bfv72%5D,value%5B1901638%2F1.m%2F159666.DST%5D&amp%3Bset=key%5Bfv73%5D,value%5Bm1621~m1918~m1827~m1801~m1800%5D

    this.loadScene()
  },
  methods: {
    loadScene () {
      // store.commit('model/setProperty', {property: 'menuVisible', value: false})

      let self = this;
      store.dispatch('scenes/setSceneLoading')
      const currentScene = store.getters['scenes/getCurrentScene']
      scenetools.createScene('player__canvas')
      geometryTools.loadModel(currentScene.scenefile).then(function(scene) {
        
        environmentTools.buildSkybox()
        cameraTools.buildCamera()

        geometryTools.buildGround('ground')
        configure.SetLighting()
        configure.SetEnvironment()
        configure.SetPostProcessing()
        self.initMaterialItems()
        init.initViews(currentScene.view)
        scenetools.runRenderLoop()
        if (!store.state.ui.headless) {
          store.dispatch('ui/openDrawer')
        } else {
          store.dispatch('ui/closeDrawer')
        }
        if (store.state.ui.inspector) {
          scene.debugLayer.show({overlay: true})
        }

        console.log('scene', scene)
        scenetools.engine.resize()
        scenetools.scene.render()
      })

    },

    initMaterialItems () {
      materialTools.setupMaterials(store.state.material.all, environmentTools.hdr)
      init.initMaterials()
    },
    onCanvasResize () {
      scenetools.engine.resize()
      if (scenetools.scene?.activeCamera) {
        scenetools.scene.render()
      }
    }
  }  
}
</script>

<template>
  <v-container class="fill-height" fluid>
    <splash v-if="!loaded"/>
    <textureload/>
    <!-- <annotationoverlay v-if="loaded" :cameraMoves="cameraMoves"/> -->
    <!-- <iframe class="player" :class="{'iframe__hidden': !loaded}" id="player__iframe" :style="myStyle"></iframe> -->
    <canvas ref="canvas" class="player" :class="{'canvas__hidden': !loaded}" id="player__canvas" :style="myStyle"></canvas>
  </v-container>
</template>

<style lang="scss" scoped>
.fill-height {
  padding:0px;
}

.player {
  width: 100%;
  height: 100%;
  border: 0;
  transition: opacity 2.0s ease;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index:0;

  &.canvas__hidden {
    opacity: 0;
  }
.progress {
  position: absolute;
  background-color: #FFFFFF;
  color: #707070;
  font-size:50px;
  text-align:center;
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
  z-index: 10;
  width: 100%;
  height: 100%;
}

.spinner {
	position: fixed;
	border-radius: 50%;
	height: 40px;
	width: 40px;
	transform: scale(0);
	background: rgba(255, 255, 255, 0.2);
	opacity: 1;
  animation: spinneranimation 800ms linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
}
@keyframes spinneranimation {
	to {
		transform: scale(1.5);
		opacity: 0;
	}
}  
}

</style>
