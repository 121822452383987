<script>
import configurator from './configurator'

export default {
    name: 'modelpickeritem',
    components: {
    },
    props: {
        scene: Object
    },
    computed: {
        bannerpath () {
            return process.env.BASE_URL + this.scene.banner
        },
        isActive () {
            if (this.scene.sceneuid) {
                return this.$store.getters['scenes/getCurrentScene'].sceneuid === this.scene.sceneuid
            } else {
                return this.$store.getters['scenes/getCurrentScene'].scenefile === this.scene.scenefile

            }
        },
        ctaText () {
            // checks if this model is the currently active one
            if (this.isActive) { return 'Reload garment' } else { return 'Select garment' }
        }    
    },
    methods: {
        selectModel () {
            this.$store.dispatch('ui/closeDrawer')
            this.$router.push({name:'scene', params: { id: this.scene.route}})
            this.$store.dispatch('scenes/setCurrentScene', {scene: this.scene})
        }
    }
}
</script>

<template>
    <v-card 
        color="card" 
        elevation=6
    >
        <v-img 
            v-ripple="{class:'primary--text'}" 
            class="primary--text align-end" 
            :src="bannerpath" 
            @click="selectModel()" 
            alt="banner"
        >
            <v-card-text 
                v-if="isActive"
            >
                This is the current model
            </v-card-text>
        </v-img>
        <v-card-title 
            class="text-h5 font-weight-thin"
        >
            {{ scene.scenetitle }}
        </v-card-title>
        <v-card-text 
            class="textcontrast--text"
        >
            {{ scene.description }}
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>    
            <v-btn 
                depressed 
                :class="isActive ? 'card' : 'primary'" 
                @click="selectModel()" 
            >
                {{ this.ctaText }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
