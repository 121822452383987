<script>
import configuratordrawer from 'Components/configurator-drawer'
import bottomnavigation from 'Components/bottom-navigation'
import playerBabylon from 'Components/babylon/player'
import fabcommercial from 'Components/fab-commercial'

import projectData from 'ProjectData/project'
import store from '../store'

export default {
  components: {
    configuratordrawer,
    bottomnavigation,
    playerBabylon,
    fabcommercial
  },    
  props: {
    source: String,
  },
  data: () => ({
    // drawer: false,
    dataParsedBabylon: false,
    playerkey: 0
    // drawer: true
  }),
  created () {
    console.log('CONFIGURATOR CREATED')

    this.$store.dispatch('ui/fetchData', {ui: projectData.ui})
    this.$store.commit('scenes/init', projectData.scenes)
    const scenes = this.$store.getters['scenes/getAll']

    if (scenes.length === 1) {
      const scene = this.$store.getters['scenes/getScene'](scenes[0].sceneuid)
      this.$store.dispatch('scenes/setCurrentScene', {scene: scene})
    }

    // read the scene id from the route. Push to a scene directly if we can find it
    if (this.$route.params.id) {
      const theScene = scenes.find(scene => scene.route === this.$route.params.id)
      if (theScene) {
        this.$store.dispatch('scenes/setCurrentScene', {scene: theScene})
      }
    }

    if (this.currentScene.sceneuid === undefined && this.currentScene.scenefile === undefined) {
      //open the models panel
      this.$router.push({name:'models'})
    } else {
      this.parseProjectData(this.currentScene)
      if (this.currentScene.engine == 'babylon') {
        this.dataParsedBabylon = true
      }
    }
  },
  computed: {
    // drawer () {
    //   return this.$store.getters['ui/getDrawer']
    // },
    drawer: {
      get: function () {
        return this.$store.getters['ui/getDrawer']
      },
      set: function (newValue) {
        store.commit('ui/setDrawer', newValue)
      }
    },

    currentScene () {
      return this.$store.getters['scenes/getCurrentScene']
    },
    ui () {
      return this.$store.getters['ui/getState']
    },
    headless () {
      return this.ui.headless
    },
    headerlogo () {
      if (this.ui.headerlogo) {
        return (process.env.BASE_URL + this.ui.headerlogo)
      } else {
        return null
      }
    },
    backgroundColor () {
      let bg = {'background-color': '#FFFFFF'}
      if (this.ui.background && this.ui.background.type != 'sketchfab') {
        bg['background-color'] = this.ui.background.color
      }
      return bg
    },
    background () {
      let bg = {image: null, type: 'sketchfab'}

      if (this.ui.background && this.ui.background.type === 'image') {
        bg.type = this.ui.background.type
        // if (this.ui.background.type === color)
        const imagePath = require.context('../assets/', true)
        bg.image = imagePath(this.ui.background.image, true)
      }
      return bg
    },
    navwidth () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '275'
        case 'sm': return '275'
        case 'md': return '350'
        case 'lg': return '350'
        case 'xl': return '350'
        default: return '350'
      }
    },
    cardcommercialsize () {

      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '275'
        case 'sm': return '275'
        case 'md': return '350'
        case 'lg': return '350'
        case 'xl': return '350'
        default: return '350'
      }
    },
    appbarHeight () {
      if (this.$vuetify.breakpoint.name === 'xs') {
        return '56'
      } else {
        return '96'
      }
    },
    drawerHeight () {
      const top = this.$vuetify.application.top
      return `calc(100% - ${top}px)`
    },
    contentPadding () {
      const isExpanded = store.getters['ui/getItemByName']('bottomnav')
      if (this.$vuetify.breakpoint.smAndUp && store.state.ui.drawer) {
        store.commit('ui/updateItemByName', {key: 'bottomnav', value: true})
      }
      if (this.$vuetify.breakpoint.smAndUp) {
        return `max-height: 100%`
      } else if (isExpanded) {
        return `max-height: calc(100% - ${this.bottomnavheight})`
      } else {
        return `max-height: calc(100% - ${this.$vuetify.application.top}px)`
      }
    },
    bottomnavheight () {
      const isExpanded = store.getters['ui/getItemByName']('bottomnav')

      if (isExpanded) {
        return `calc(60% - ${this.$vuetify.application.top}px)`
      } else {
        return '56'
        // return `calc(60% - ${top}px)`//`56px`
      }
    }
  },
  mounted () {
    this.$nextTick(function () {
      window.addEventListener('resize', this.getWindowWidth)
      window.addEventListener('resize', this.getWindowHeight)

      this.getWindowWidth()
      this.getWindowHeight()



    })    
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.getWindowWidth)
    window.removeEventListener('resize', this.getWindowHeight)
  },  
  methods: {
    forceRerenderPlayer() {
      this.playerkey += 1
    },
    parseProjectData (scene) {
      console.log('FETCHING DATA')
      store.dispatch('menu/fetchData', {menu: scene.menu})
      store.dispatch('materialswappable/fetchData', {menu: scene.menu})
      store.dispatch('material/fetchData', {menu: scene.menu})
      store.dispatch('environment/fetchData', {scene: scene})
      store.dispatch('views/fetchData', {menu: scene.menu})
      store.dispatch('presets/fetchData')
      store.dispatch('liquifire/fetchData')
      store.dispatch('text/fetchData')
      store.dispatch('liquid/fetchData')
      store.dispatch('keygroups/fetchData')
      store.dispatch('visibilitysingle/fetchData', {menu: scene.menu})
      store.dispatch('visibilitygroup/fetchData', {menu: scene.menu})
      store.dispatch('transform/fetchData', {menu: scene.menu})

      store.dispatch('labels/fetchData', {scene: scene})
      store.dispatch('player/fetchData', {scene: scene})
      store.dispatch('background/fetchData', {scene: scene})
      // store.dispatch('environment/fetchData', {scene: scene})
      store.dispatch('postprocessing/fetchData', {scene: scene})
      store.dispatch('lighting/fetchData', {scene: scene})
      console.log('FETCHED DATA')
    },
    loadScene (scene) {
      this.parseProjectData(scene)
    },
    getWindowWidth () {
      this.windowWidth = document.documentElement.clientWidth
    },
    getWindowHeight () {
      this.windowHeight = document.documentElement.clientHeight
    },
    clickHamburger () {
      if (this.drawer) store.dispatch('ui/closeDrawer'); else store.dispatch('ui/openDrawer');
    },
    onResize () {
      if (window.innerHeight < 700) {
        store.commit('ui/updateItemByName', {key: 'cardcommercialbig', value: false})
      }else{
        store.commit('ui/updateItemByName', {key: 'cardcommercialbig', value: true})
      }
    },    
  }
}
</script>

<template>
  <v-app id="configurator">

    <v-navigation-drawer
      app 
      :value="drawer" 
      clipped 
      :width="navwidth"
      fixed
      class="pb-0"
      :height="drawerHeight"

      mobile-breakpoint=0 
      color="card"
      style="max-height: 100%;"
      v-if="$vuetify.breakpoint.smAndUp"
      v-resize="onResize"

      disable-resize-watcher
    >
      <configuratordrawer v-if="dataParsedBabylon"/>
    </v-navigation-drawer>

    <v-bottom-navigation
      app
      fixed
      :height="bottomnavheight"
      horizontal
      v-if="$vuetify.breakpoint.xsOnly"
      color="black"
      background-color="card"
    >
      <bottomnavigation/>

    </v-bottom-navigation>

    <v-app-bar 
      app 
      fixed 
      clipped-left 
      :height="appbarHeight" 
      elevation=1
      color="card"
      v-if="!headless"
    >
      <v-app-bar-nav-icon 
        @click.stop="drawer = !drawer"
        v-if="$vuetify.breakpoint.smAndUp"
      >
      </v-app-bar-nav-icon>
      <v-sheet 
        class="titles"
        color="card"
      >
        <!-- <div class="text-h1 hidden-xs-only text-truncate">{{ currentScene.scenetitle }}</div> -->
        <div class="hidden-xs-only text-truncate text-h4 font-weight-light">{{ currentScene.scenetitle }}</div>
        <div class="hidden-sm-and-up text-truncate text-h6 font-weight-light">{{ currentScene.scenetitle }}</div>
        <!-- <h3 class="hidden-sm-and-up text-truncate">{{ currentScene.scenetitle }}</h3> -->
        <div class="hidden-xs-only text-truncate text-sm-h6 font-weight-thin text--secondary">{{ ui.projecttitle }}</div>
        <div class="hidden-sm-and-up text-truncate text-sm-subtitle-1 font-weight-thin text--secondary">{{ ui.projecttitle }}</div>
        <!-- <div class="subtitle-1 d-none d-sm-block">{{ ui.projecttitle }}</div> -->
      </v-sheet>
      <v-spacer></v-spacer>
      <v-img class="headerlogo" v-if="headerlogo" contain max-height="76" :src="headerlogo"></v-img>
    </v-app-bar>
      <fabcommercial v-if="ui.infopanel"/>

    <v-main 
      class="content pb-0"
      :style="contentPadding"
    >
      

          
      <playerBabylon v-if="dataParsedBabylon" :key="playerkey"/>
      <v-card class="background" :style="backgroundColor" :class="'d-flex align-center'" flat>
        <v-img class="backgroundimage" v-if="background.image" :src="background.image" contain style="z-index: 1"></v-img>
      </v-card>
    </v-main>

  </v-app>
</template>

<style lang="scss" scoped>
  .titles {
    margin-left: 12px;
  }
  .backgroundimage {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    height: 70%;
    opacity: 0.4;
  }
  .background {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 0px;
  }
  .headerlogo {
    max-width: 250px;
    
    @media screen and (max-width: 768px) {
      max-width: 120px;
    }    
  }
  .navdrawer {
    @media screen and (max-width: 600px) {
      max-height: 65%;
    }      
  }
</style>

