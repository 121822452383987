import {Engine} from '@babylonjs/core';
import {Scene} from '@babylonjs/core';

let scene, canvas, engine;

const createScene = function (elementId) {

    // Get the canvas DOM element
    canvas = document.getElementById(elementId);
    // Load the 3D engine
    engine = new Engine(canvas, true, {preserveDrawingBuffer: true, stencil: true});
    
    scene = new Scene(engine);
 
}

const getMesh = function (name) {
  let sc = getScene()
  const nodes = sc.meshes.filter(node => node.name === name)
  return nodes
}

const clear = function () {
  scene = null
  canvas = null
  engine = null
}

const getScene = function () {
  return scene
}

const getCanvas = function () {
  return canvas
}

const runRenderLoop = function () {
  // run the render loop
  engine.runRenderLoop(function(){
    scene.render();
  });
}

const show = function (name, matchNameExactly) {
  _setObjectVisibility(name, true, matchNameExactly)
}

const hide = function (name, matchNameExactly) {
  _setObjectVisibility(name, false, matchNameExactly)
}

const _setObjectVisibility = function (name,doShow){
  let objects = getMesh(name)

  objects.forEach(object => {
    object.visibility = doShow
  });
  
  //update the scene
  scene.render();		
}

export default { 
  createScene, 
  getScene, 
  getCanvas, 
  runRenderLoop, 
  getMesh, 
  show, 
  hide,
  clear,
  get scene () {
    return scene
  },
  get engine () {
    return engine
  },
  get canvas () {
    return canvas
  }
}
