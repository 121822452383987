

//BJS
import environmentToolsBJS from 'bjs_tool/environment'
import postprocessingToolsBJS from 'bjs_tool/post'
import lightingToolsBJS from 'bjs_tool/lighting'
import materialToolsBJS from 'bjs_tool/materials'
import cameraToolsBJS from 'bjs_tool/camera'
import sceneToolsBJS from 'bjs_tool/scene'
import textToolsBJS from 'bjs_tool/text'


import store from '@/store'


const _isBabylon = function () {
  return (store.state.scenes.currentScene.engine === 'babylon')
}

const _isSketchfab = function () {
  return (store.state.scenes.currentScene.engine === null || store.state.scenes.currentScene.engine === undefined || store.state.scenes.currentScene.engine === 'sketchfab')
}

const SetVisibilitySingle = async function (isVisible, names, matchNameExactly) {
  if (matchNameExactly === undefined) {
    matchNameExactly = false
  }

  if (_isBabylon()) {
    for (let name of names) {
      if (isVisible) {
        sceneToolsBJS.show(name, matchNameExactly)
      } else {
        sceneToolsBJS.hide(name, matchNameExactly)
      }
    }
  }

}

const SetVisibilityGroup = async function (group, option, matchNameExactly) {
  if (matchNameExactly === undefined) {
    matchNameExactly = false
  }  
  // first, hide all options from this group
  for (let options of group.options) {
    await SetVisibilitySingle(false, options.objects, matchNameExactly)
  }
  // Then, show the picked option
  await SetVisibilitySingle(true, option.objects, matchNameExactly)
}



const _setSingleMaterialOptionBJS = function (objectNames, materialId) {
  const bjsMtl = materialToolsBJS.getMtl(materialId)
  materialToolsBJS.applyMaterialByNames(objectNames, bjsMtl)
}

 const SetMaterialOption = async function (material, option, liquidPayload) {
  if (_isBabylon()) {
    if (material.type === null || material.type === 'single') {
      _setSingleMaterialOptionBJS(material.object, option.id)
    }
  }


}

const translate = function (transform, option) {

}

const SetPostProcessing = function () {
  let payload = store.getters['postprocessing/getPayload']
  if (payload !== undefined) {
    if (_isBabylon()) {
      postprocessingToolsBJS.setupPostProcess(payload)
    }


  }
}


const SetEnvironment = function (payload) {
  if (payload === undefined) {
    payload = store.getters['environment/getPayload']
  }

  if (payload !== undefined) {
    if (_isBabylon()) {
      environmentToolsBJS.makeEnvHdr(payload.environmentmap)
      // environmentToolsBJS.buildSkybox()
      environmentToolsBJS.setupEnvironment(payload)
    }

  }
}


const SetLighting = function () {
  const payload = store.getters['lighting/getPayload']

  if (payload !== undefined) {
    if (_isBabylon()) {
      lightingToolsBJS.setupLighting(payload.lights)
      lightingToolsBJS.setupShadows(payload.shadowgenerator)
      lightingToolsBJS.addShadowCaster()
    }

  }
}

const SetTextOption = function (theOption) {
  if (theOption != null){
    console.log('theOption', theOption)
    let bjsMtl = materialToolsBJS.getSceneMtl(theOption.scenematerial)
    console.log('bjsMtl', bjsMtl)
    if (bjsMtl != null && bjsMtl != undefined){			
      textToolsBJS.SetText(theOption.text, bjsMtl, theOption.payload)
    }
  }
}

const setView = function (viewItem) {
  if (_isBabylon()) {
    cameraToolsBJS.editActiveCamera(viewItem)
  }

}



const _printView = function () {
  if (_isBabylon()) {

  }


}


const _printSceneGraph = function () {
  if (_isBabylon()) {
    const sc = sceneToolsBJS.getScene()
    sc.meshes.forEach(mesh => {
      console.log(mesh.name)
    });
  }


}

const setPreset = function (options) {
  options.forEach(option => {

    if (option.visibilitysingle !== undefined) {
      console.log('option.visibilitysingle', option.visibilitysingle)
      store.commit('visibilitysingle/setVisibility', {optionName: option.visibilitysingle, switch: option.visible})
      let visibilityItem = store.getters['visibilitysingle/getItemByName'](option.visibilitysingle)
      SetVisibilitySingle(option.visible, visibilityItem.objects, visibilityItem.exactname)      
    }
    
    if (option.visibilitygroup !== undefined) {
      console.log('option', option)
      const visibilityGroupItem = store.getters['visibilitygroup/getItemByName'](option.visibilitygroup)
      const visibilityOption = visibilityGroupItem.options.find(item => item.name === option.visible)
      store.dispatch('visibilitygroup/updateSelectedGroup', {group: visibilityGroupItem, option: option.visible})
      SetVisibilityGroup(visibilityGroupItem, visibilityOption, visibilityOption.matchNameExactly)
    }
    
    if (option.transform !== undefined) {
      const transform = store.getters['transform/getItemByName'](option.transform)
      const transformOption = transform.options.find(item => item.name === option.default)
      store.dispatch('transform/setCurrentTransform', {transform: transform, option: transformOption})
      translate(transform, transformOption)
    }
    
  })
}




window.configuratorapi.printView = _printView
window.configuratorapi.printSceneGraph = _printSceneGraph

export default {
  SetMaterialOption,
  SetVisibilitySingle,
  SetVisibilityGroup,
  setView,
  translate,
  setPreset,
  SetPostProcessing,
  SetTextOption,
  SetEnvironment,
  SetLighting,
}
